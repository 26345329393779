import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Typography,
  Card,
  styled,
  Drawer,
  Button,
  Modal,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Avatar,
  Paper,
  List,
  ListItem,
  Popover
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { circleimg,sendicon,addImg, checkCircle } from "../src/assets"
import { Toaster } from 'react-hot-toast';
import Picker from "emoji-picker-react";
import moment from "moment"
import CloseIcon from '@material-ui/icons/Close';
import BlockIcon from '@material-ui/icons/Block';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import SendIcon from '@material-ui/icons/Send';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotionsOutlined';
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import ViewChatController, { configJSON, IMessage, Props } from "./ViewChatController";

// Customizable Area Start
// Customizable Area End

export default class ViewChat extends ViewChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  groupInfosidebaar() {
    return (
      <>
        <BoxMain>
          <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px" }}>
            <Typography style={{ paddingLeft: "10px", fontFamily: "MyFont", fontWeight: "bold", fontSize: "20px", letterSpacing: "0.8px" }}>Group Info</Typography>
            <span onClick={this.toggleDrawerClose} style={{ cursor: "pointer" }}><i className="fa-solid fa-xmark"></i></span>
          </Box>
          <Box style={webStyles.borderBottomstyle as any}></Box>

          <Container>
            <Box style={{ textAlign: "center" }}>
              <img src={this.state.groupInfo.group_info?.group_profile_image?.url} alt="" style={{ width: "100px", height: "100px", marginTop: "25px", borderRadius: "50%" }} />
              <Typography style={{ fontFamily: "MyFont", fontWeight: "bold", fontSize: "20px", letterSpacing: "0.8px" }}>{this.state.roomname}</Typography>
              <Typography style={{ fontFamily: "MyFont", letterSpacing: "0.8px", fontSize: "10px", color: "#64748B" }}>{this.state.groupInfo.group_info?.number_of_participants} participants</Typography>
              <Typography style={{ fontFamily: "MyFont", color: "#0F172A", letterSpacing: "0.8px", fontSize: "14px" }}>{this.state.groupdescription}</Typography>
              <Typography style={{ fontFamily: "MyFont", color: "#0F172A", letterSpacing: "0.8px", fontSize: "14px", marginTop: "10px" }} onClick={this.editopen}>Edit Group</Typography>
            </Box>
            <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px", marginTop: "5px" }}>
              <Typography style={{ fontFamily: "MyFont", color: "#64748B", letterSpacing: "0.8px", fontSize: "14px" }}>
                {this.state.groupInfo.group_info?.number_of_participants} participants
              </Typography>
              <Typography onClick={this.handleAddmemberDrawer}>
                <i className="fa-solid fa-plus" style={{ cursor: "pointer", border: "1px solid #14362E", padding: "2px", borderRadius: "50%" }}></i>
              </Typography>
            </Box>

            {
              this.state.groupMember.map((element: any, index: any) => {
                return (
                  <>
                    <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px", marginTop: "5px" }}>
                      <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <img src={element.profile_image?.url} alt="" style={{ width: "40px", marginRight: "5px", borderRadius: "50%", height: "40px" }} />
                        <Typography style={{ fontFamily: "MyFont", color: "#64748B", letterSpacing: "0.8px", fontSize: "14px" }}>
                          {element.first_name} {element.last_name} <span style={{ color: "#065F46", fontFamily: "MyFont", letterSpacing: "0.5px", fontSize: "10px" }}>{element.role == "admin" && "Admin"}</span>
                        </Typography>
                      </Box>
                      <Box>
                        <Typography>
                          <i className="fa-solid fa-user"></i>
                          <i className="fa-solid fa-circle-minus" style={{ color: "#D1584C", marginLeft: "3px", cursor: "pointer" }} onClick={this.removemember.bind(this, element.id)}></i>
                        </Typography>
                      </Box>
                    </Box>
                  </>
                )
              })
            }

            <Box style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", color: "#64748B", padding: "10px", width: "150px", marginTop: "4px" }} onClick={this.leavegroup}>
              <i className="fa-solid fa-right-from-bracket" style={{ marginRight: "5px" }}></i>
              <Typography style={{ fontFamily: "MyFont", fontSize: "15px", fontWeight: "bold", letterSpacing: "0.8px", cursor: "pointer" }}>Leave Group</Typography>
            </Box>
            <Box style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", color: "#D1584C", padding: "10px", width: "150px", marginTop: "4px" }} onClick={this.reportSidebaaropen}>
              <i className="fa-solid fa-circle-exclamation" style={{ marginRight: "5px" }}></i>
              <Typography style={{ fontFamily: "MyFont", fontSize: "15px", fontWeight: "bold", letterSpacing: "0.8px", cursor: "pointer" }}>Report Group</Typography>
            </Box>
          </Container>
        </BoxMain>
      </>
    )
  }

  memberSiderbaar() {
    return (
      <>

        <BoxMain>
          <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px" }}>
            <Typography style={{ paddingLeft: "10px", fontFamily: "MyFont", fontWeight: "bold", fontSize: "20px", letterSpacing: "0.8px" }} >Add Member</Typography>
            <span onClick={this.drawermemberclose} style={{ cursor: "pointer" }}><i className="fa-solid fa-xmark"></i></span>
          </Box>
          <Box style={webStyles.borderBottomstyle as any}></Box>

          <Box style={{ padding: "10px" }}>
            <Box style={webStyles.searchbox}>

              <Box style={{ padding: "10px", }}>
                <i className="fa-solid fa-magnifying-glass"></i>&nbsp;&nbsp;
                <input type="text" name="searchvalue" value={this.state.addmembervalue} onChange={this.searchvalue} style={webStyles.inputbox} placeholder='Search' id="search"
                />
              </Box>
              <i className="fa-solid fa-xmark" onClick={this.removevalue} style={{ color: "#0F172A", marginRight: "5px", cursor: "pointer" }}></i>
            </Box>
          </Box>

          <Box style={{ display: "flex", alignItems: "center", fontFamily: "MyFont",flexWrap:"wrap" }}>
            {
              this.state.selectedmember && this.state.selectedmember.map((element: any, index: any) => (
                <Box key={index} style={{ display: "flex", alignItems: "center", minWidth: "100px", background: "#F1F5F9", borderRadius: "20px", flexWrap: "wrap", marginBottom: "15px" }}>
                  <Typography style={{ fontSize: "10px", color: "#475569", padding: "5px", marginRight: "8px" }}>{element.value}</Typography>
                  <span onClick={this.handleselectmemberDelete.bind(this, index)}><i className="fa-solid fa-xmark" style={{ fontSize: "10px" }}></i></span>
                </Box>
              ))
            }

          </Box>
          <Box>
            {
              this.state.MemberListData.length > 0 && this.state.MemberListData.map((element: any, index: any) => {
                const checkdata = this.state.selectedmember.map((ele: any) => ele.id == element.id)
                return (
                  <>
                    <Box key={index} style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "4px", marginBottom: "10px", marginTop: "10px", minHeight: "60px" }}>
                      <Box style={{ display: "flex", borderRadius: "10px", alignItems: "center" }}>

                        <Box style={{ marginLeft: "10px", fontFamily: "MyFont", display: "flex", alignItems: "center" }}>
                          <img src={element.profile_picture?.url} style={{ width: "40px", height: "40px", borderRadius: "50%" }} alt="" />
                          <Box style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            <Typography style={{ fontFamily: "MyFont", letterSpacing: "0.8px", marginLeft: "4px" }}>{element.first_name + element.last_name}</Typography>
                            <Typography style={{ fontFamily: "MyFont", letterSpacing: "0.8px", marginLeft: "4px", fontSize: "10px" }}>{element.role}</Typography>
                          </Box>
                        </Box>
                      </Box>

                      <span><input type="checkbox" value={element.id} checked={checkdata.some((eleme:any)=>eleme)} onClick={(elementvalue) => this.getvaluemembercheck(elementvalue, element)} name="value member" /></span>
                    </Box>
                  </>
                )
              })
            }
          </Box>

          <Container>
            <Button variant="contained" style={{ width: "100%", background: "black", color: "white", marginTop: "400px",fontFamily:"MyFont",textTransform:"capitalize",letterSpacing:"0.8px" }} onClick={this.addmemberapi}>Add Member</Button>
          </Container>
        </BoxMain>
      </>
    )
  }

  ReportSidebaar() {
    return (
      <>
        <BoxMain>
          <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px" }}>
            <Typography style={{ paddingLeft: "7px", fontFamily: "MyFont", fontSize: "17px", fontWeight: "bold", letterSpacing: "0.8px", marginTop: "5px" }}>Report Group</Typography>
            <span style={{ cursor: "pointer" }} onClick={this.reportSidebaarClose}><i className="fa-solid fa-xmark"></i></span>
          </Box>

          <Box style={webStyles.borderBottomstyle as any}></Box>

          <Box style={{ padding: "10px" }}>
            <Typography style={{ paddingLeft: "12px", fontFamily: "MyFont", fontWeight: "bold", letterSpacing: "0.8px" }}>Why are you flagging this group?</Typography>
          </Box>
          <Box style={webStyles.borderBottomstyle as any}></Box>
          {
            this.state.reportingListing.length > 0 && this.state.reportingListing.map((element: any, index: any) => {
              return (
                <>
                  <Box style={{ display: "flex", alignItems: "center", background: this.state.setBlockKey === index ? "#D4E5DE" : "", maxWidth: "500px" }} >
                    <Box style={{ padding: "10px", cursor: "pointer", width: "100%" }} onClick={this.setblockcolor.bind(this, element, index)}>
                      <Typography style={{ paddingLeft: "12px" }}>{element.title}</Typography>
                      <Typography style={{ paddingLeft: "12px" }}>{element.description}</Typography>
                    </Box>
                    {
                      this.state.setBlockKey === index &&
                      <i style={{ marginRight: "10px", border: "1px solid #14362E", padding: "3px", borderRadius: "50%" }} className="fa-solid fa-check"></i>
                    }
                  </Box>
                  <Box style={webStyles.borderBottomstyle as any}></Box>
                </>
              )
            })
          }

          <Box style={{ padding: "10px", marginTop: "200px" }}>
            <Button variant="contained" style={{ background: "#14362E", color: "#fff", width: "100%", textTransform: "capitalize", fontFamily: "MyFont", letterSpacing: "0.8px", fontWeight: "bold", fontSize: "13px" }} onClick={this.reportuser}>Report</Button>
          </Box>
        </BoxMain>
      </>
    )
  }

  EditSidebaar() {
    return (
      <>
        <BoxMain>
          <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px" }}>
            <Typography style={{ fontFamily: "MyFont", fontWeight: "bold", letterSpacing: "0.8px", fontSize: "21px" }}>Edit Group</Typography>
            <span onClick={this.drawerClose} style={{ cursor: "pointer" }}><i className="fa-solid fa-xmark"></i></span>
          </Box>
          <Box style={webStyles.borderBottomstyle as any}></Box>
          <Container>
            <Box style={{ display: 'flex', flexDirection: "column", alignItems: "center", padding: "10px" }}>
              <Box style={{ position: "relative" }}>
                <label htmlFor="updateprofile">
                  <img src={this.state.selectedImage ? this.state.selectedImage : this.state?.groupInfo?.group_info?.group_profile_image?.url} style={{ width: "100px", height: "100px", borderRadius: "50%", marginTop: "10px" }} alt="" />
                </label>
                <input type="file" id="updateprofile" onChange={this.handleImgupdate} name="" style={{ display: "none" }} />
              </Box>
              <Box style={{ width: "100%", marginBottom: "10px" }}>
                <label htmlFor="" style={{ fontFamily: "MyFont", fontWeight: "bold", letterSpacing: "0.8px" }}>Group Name</label> <br />
                <input type="text" value={this.state.updatedgroupname} onChange={this.handleinputchange} style={{ width: "100%", padding: "10px 5px", borderRadius: "5px", border: "1px solid #CBD5E1", marginBottom: "20px" }} name="groupname" id="" />

                <label htmlFor="" style={{ fontFamily: "MyFont", fontWeight: "bold", letterSpacing: "0.8px" }}>Description</label> <br />
                <textarea value={this.state.updateddescription} onChange={this.handledescriptionchange} style={{ width: "100%", padding: "10px 5px", borderRadius: "5px", border: "1px solid #CBD5E1" }} rows={4} name="groupdescription" id="" />

              </Box>
            </Box>
          </Container>

          <Container>
            <Button variant="contained" style={{ width: "100%", background: "black", color: "white", marginTop: "300px",textTransform:"capitalize",fontFamily:"MyFont",letterSpacing:"0.8px" }} onClick={this.handleUpdategroup}>Save</Button>
          </Container>
        </BoxMain>
      </>
    )
  }

  insidechatDataDisplay(){
    return (
      <>
          {
                this.state.chatData.length > 0 ? this.state.chatData.map((element: any, index: any) => {
                  return (
                    <>

                      {
                        element.attributes?.sender.id == this.state.currentUserID ? (
                          <>

                            <Box style={{ display: "flex", justifyContent: "end", marginTop: "5px" }}>
                              <Typography style={{ background: "#D4E5DE", color: "#0F172A", padding: "10px", borderTopLeftRadius: "13px", borderBottomLeftRadius: "13px", borderBottomRightRadius: "13px", maxWidth: "300px", wordWrap: "break-word", fontFamily: "MyFont", fontSize: "13px", letterSpacing: "0.8px" }}>
                                {element.attributes?.content} <br />
                                {element.attributes?.media_url.url && <img src={element.attributes.media_url.url} style={{ width: "100px", height: "100px", marginTop: "5px" }} alt="" />}
                                {
                                  (element.attributes?.share?.media?.length || 0) > 0 && (
                                    <>
                                      <Box style={{ padding: "10px", display: "flex", color: "#0F172A", justifyContent: "center", alignItems: "center", background: "white", borderTopLeftRadius: "13px", borderBottomLeftRadius: "13px", borderBottomRightRadius: "13px", maxWidth: "300px", wordWrap: "break-word", fontFamily: "MyFont", fontSize: "13px", letterSpacing: "0.8px" }} onClick={this.handleRedirectHome.bind(this, element.attributes.share.id)}>
                                        {<img src={element.attributes.share.media[0].url} style={{ width: "40px", height: "40px" }} alt="" />}

                                        <Box>
                                          <Typography style={{ marginLeft: "8px", fontSize: "12px", fontFamily: "MyFont" }}>
                                            {element.attributes.share.body}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </>
                                  )
                                }

                                {
                                  (element.attributes.share !== null && element.attributes.share.type === "comment") && (
                                    <Box style={{ color: "#0F172A", padding: "10px", display: "flex", justifyContent: "center", alignItems: "center", background: "white", borderTopLeftRadius: "13px", borderBottomLeftRadius: "13px", borderBottomRightRadius: "13px", maxWidth: "300px", wordWrap: "break-word", fontFamily: "MyFont", fontSize: "13px", letterSpacing: "0.8px" }}>

                                      <Typography style={{ letterSpacing: "0.8px", fontFamily: "MyFont" }}>{element.attributes.share.comment}</Typography>

                                    </Box>
                                  )
                                }
                              </Typography>



                              <img style={{ height: "35px", borderRadius: "50%", marginLeft: "3px", width: "35px" }} src={element.attributes.sender?.profile_picture.url} alt="" />
                            </Box>
                          </>
                        ) : (
                          <>
                            <Box style={{ display: "flex", justifyContent: "flex-start", marginTop: "50px" }}>

                              <img style={{ width: "35px", height: "35px", borderRadius: "50%", marginRight: "3px" }} src={element.attributes.sender.profile_picture?.url} alt="" />
                              <Box style={{ background: "#E2E8F0", padding: "10px", borderBottomLeftRadius: "13px", borderBottomRightRadius: "13px", borderTopRightRadius: "13px", maxWidth: "300px", wordWrap: "break-word" }}>
                                <Typography style={{ fontFamily: "MyFont", fontSize: "15px", letterSpacing: "0.8px", color: "#0F172A", fontWeight: "bold" }}>{element.attributes?.sender.name}</Typography>
                                <Typography style={{ fontFamily: "MyFont", fontSize: "13px", letterSpacing: "0.8px", color: "#0F172A" }}>{element.attributes?.content} <br />
                                  {
                                    element.attributes?.media_url.url &&
                                    <img src={element.attributes.media_url.url} style={{ width: "100px", height: "100px", marginTop: "5px" }} alt="" />
                                  }
                                </Typography>
                                {
                                  (element.attributes?.share?.media?.length || 0) > 0 && (
                                    <>
                                      <Box style={{ padding: "10px", fontStyle: "normal", display: "flex", justifyContent: "center", alignItems: "center", color: "#0F172A", borderTopLeftRadius: "13px", background: "#fff", borderBottomLeftRadius: "13px", borderBottomRightRadius: "13px", maxWidth: "300px", wordWrap: "break-word", fontFamily: "MyFont", fontSize: "13px", letterSpacing: "0.8px" }} onClick={this.handleRedirectHome.bind(this, element.attributes.share.id)}>
                                        {
                                          <img src={element.attributes.share.media[0].url} style={{ width: "40px", height: "40px" }} alt="" />
                                        }
                                        <Box>

                                          <Typography style={{ color: "#0F172A", marginLeft: "8px", fontStyle: "normal", fontSize: "12px", fontFamily: "MyFont" }}>

                                            {element.attributes.share.body}

                                          </Typography>

                                        </Box>

                                      </Box>
                                    </>
                                  )
                                }
                                {
                                  (element.attributes.share !== null && element.attributes.share.type === "comment") && (

                                    <Box style={{ display: "flex", color: "#0F172A", padding: "10px", alignItems: "center", background: "white", flexDirection: "column", borderTopLeftRadius: "13px", borderBottomLeftRadius: "13px", borderBottomRightRadius: "13px", justifyContent: "center", maxWidth: "300px", wordWrap: "break-word", fontFamily: "MyFont", fontSize: "11px", letterSpacing: "0.8px" }}>

                                      <Typography style={{ fontFamily: "MyFont", fontSize: "13px", letterSpacing: "0.8px" }}>{element.attributes.share.comment}</Typography>
                                    </Box>
                                  )
                                }
                              </Box>

                            </Box>
                            <Box style={{ marginLeft: "35px" }}>
                              <Typography style={{ fontFamily: "MyFont", fontSize: "11px", letterSpacing: "0.8px", color: "#0F172A" }}>{moment(element.attributes?.created_at).format("hh:mm A")}</Typography>
                            </Box>
                          </>
                        )
                      }
                    </>
                  )
                }) : (
                  <>
                    <Box style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", height: "70vh" }}>
                      <Typography style={{ marginRight: "5px", fontFamily: "MyFont", fontWeight: "bold", letterSpacing: "0.8px" }}>No Chat</Typography>
                    </Box>
                  </>
                )
              }
      </>
    )
  }

  chatDatadisplay() {
    return (
      <>
        <Container>

          <Box>
            <Box style={webStyles.profilechat as any}>
              <Box style={{ display: "flex", alignItems: "center" }}>
                <i className="fa-solid fa-angle-left" style={{ marginRight: "5px", cursor: "pointer" }} onClick={this.goBack}></i>
                <img style={webStyles.profileImg} src={this.state.currentUserInfo.receiver?.profile_picture?.url ?this.state.currentUserInfo.receiver?.profile_picture?.url :addImg} alt="" />
                <Box>
                  <Typography style={{ fontFamily: "MyFont", fontWeight: "bold", fontSize: "16px", letterSpacing: "0.8px" }}>{this.state.currentUserInfo.receiver?.name} <img src={circleimg} alt="" /></Typography>
                  <Typography style={{ fontFamily: "MyFont", fontSize: "13px", letterSpacing: "0.8px" }}>{moment(this.state.currentUserInfo.receiver?.last_seen).fromNow()}</Typography>
                </Box>
              </Box>
              <Box onClick={(event) => this.changeChatMenuState(event.currentTarget)} style={{cursor:' pointer'}} data-test-id='moreIcon'>
                <MoreVertIcon fontSize="small"/>
              </Box>
              <Menu
                  anchorEl={this.state.chatMenu}
                  open={Boolean(this.state.chatMenu)}
                  onClose={() => this.changeChatMenuState(null)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  PaperProps={{
                    style: {transform: 'translatey(50px)'}
                  }}
                >
                  <MenuItem onClick={() => this.handleChangeRemoveDrawer(true)} style={webStyles.chatMenuItem}>
                    <ListItemIcon>
                    <CloseIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Remove from My Circle" />
                  </MenuItem>
                  <MenuItem onClick={() => this.handleChangeBlockDrawer(true)} style={webStyles.chatMenuItem} >
                    <ListItemIcon style={{color: '#D1584C'}}>
                    <BlockIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Block" style={{color: '#D1584C'}} />
                  </MenuItem>
              </Menu>
            </Box>
          </Box>

        </Container>
        <Box style={webStyles.borderBottomstyle as any}></Box>

        <Container>
          <Box>
            <Box style={{ height: "64vh", overflow: "auto", padding: "10px" }}>
            {this.insidechatDataDisplay()}
            </Box>
            <Popover
              open={Boolean(this.state.pickerAnchor)}
              anchorEl={this.state.pickerAnchor}
              onClose={() => this.changePickerAnchor(null)}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Picker style={{ width: "100%", marginTop: "20px" }} onEmojiClick={this.Emojiclick} />
            </Popover>
      
            <Box style={{
              marginTop: "20px", display: "flex", alignItems: "center", border: "1px solid #CBD5E1", padding: "12px",
              borderRadius: "10px",
              justifyContent:'space-between'
            }}>
              <Box style={{ display:'flex', alignItems:'center', flex: '1',gap:'15px' }}>
                  <label htmlFor="usefile">
                    <AttachFileIcon  style={{scale:'0.8', cursor: "pointer" }}/>
                  </label>
                  <EmojiEmotionsIcon onClick={this.openEmoji} style={{scale:'0.8', cursor: "pointer" }} data-test-id='emojiPicker'/>
                  <input type="file" name="" style={{ display: "none" }} id="usefile" onChange={this.handleFileChange} />
                  <textarea style={{ border: "none", outline: "none", width: "85%",wordBreak:"break-word", height:"auto",resize:"none", overflowY: 'auto' }} value={this.state.message} onChange={this.handleMessageChange}  data-test-id='messageBlock' rows={1} placeholder={"Message "+ this.state.currentUserInfo?.receiver?.name} />
              </Box>
              <Box style={{ width:'40px', height:'40px', cursor: "pointer",padding:"1px", background: '#14362E', color: 'white', borderRadius: '8px', display:'flex', justifyContent:'center', alignItems:'center'}} 
                data-test-id='sendMessageBtn'
                onClick={this.state.message || this.state.uploadedImages.length > 0
                  ? this.handleSendMessage.bind(this)
                  : () => { }}
                >
                  <SendIcon fontSize="small" style={{scale:"0.8", cursor: 'pointer'}}/>
              </Box>
            </Box>
            <Box style={{display:'flex', marginTop:'10px' }}>
                {this.state.uploadedImages.length > 0 && <img style={{  height: "61px", width: "61px", marginTop: "10px" }} src={this.state.uploadedImages[0].base64convert} />}
              </Box>
          </Box>
        </Container>
        {this.renderBlockUserDrawer()}
        {this.renderRemoveModal()}
        {this.blockUserConfirmationModal()}
      </>
    )
  }

  insideGroupChatDataDisplay(){
    return(
      <>
        {
                this.state.groupChatData.length > 0 ? this.state?.groupChatData.map((element: any, index: any) => {
                  return (
                    <>
                      {
                        element.attributes?.sender?.id == this.state?.currentUserID ? (
                          <>

                            <Box style={{ display: "flex", justifyContent: "end", marginTop: "5px" }}>
                              <Typography style={{ background: "#D4E5DE", color: "#0F172A", padding: "10px", borderTopLeftRadius: "13px", borderBottomLeftRadius: "13px", borderBottomRightRadius: "13px", maxWidth: "300px", fontFamily: "MyFont", fontSize: "13px", letterSpacing: "0.8px", wordWrap: "break-word" }}>
                                {element.attributes?.content} <br />
                                {element.attributes?.media_url?.url && <img src={element.attributes.media_url.url} style={{ width: "100px", height: "100px", marginTop: "5px" }} alt="" />}
                                {
                                  (element.attributes?.share?.media?.length || 0) > 0 && (
                                    <>
                                      <Box style={{ background: "white", color: "#0F172A", display: "flex", padding: "10px", justifyContent: "center", alignItems: "center", borderTopLeftRadius: "13px", borderBottomLeftRadius: "13px", borderBottomRightRadius: "13px", maxWidth: "300px", wordWrap: "break-word", fontFamily: "MyFont", fontSize: "13px", letterSpacing: "0.8px" }} onClick={this.handleRedirectHome.bind(this, element.attributes.share.id)}>
                                        {<img src={element.attributes.share.media[0].url} style={{ width: "40px", height: "40px" }} alt="" />}

                                        <Box>
                                          <Typography style={{ fontSize: "12px", fontFamily: "MyFont", marginLeft: "8px" }}>

                                            {element.attributes.share.body}

                                          </Typography>

                                        </Box>

                                      </Box>
                                    </>
                                  )
                                }
                                {
                                  (element.attributes.share !== null && element.attributes.share.type === "comment") && (
                                    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", background: "white", borderTopLeftRadius: "13px", color: "#0F172A", borderBottomLeftRadius: "13px", borderBottomRightRadius: "13px", maxWidth: "300px", padding: "10px", wordWrap: "break-word", fontFamily: "MyFont", fontSize: "13px", letterSpacing: "0.8px" }}>

                                      <Typography style={{ fontFamily: "MyFont", letterSpacing: "0.8px" }}>{element.attributes.share.comment}</Typography>

                                    </Box>
                                  )
                                }
                              </Typography>

                              <img style={{ borderRadius: "50%", width: "35px", height: "35px", marginLeft: "3px" }} src={element.attributes.sender?.profile_picture?.url} alt="" />
                            </Box>
                          </>
                        ) : (
                          <>
                            <Box style={{ display: "flex", justifyContent: "flex-start", marginTop: "50px" }}>
                              <img
                                style={{ width: "35px", borderRadius: "50%", marginRight: "3px", height: "35px" }}
                                src={element.attributes.sender.profile_picture?.url}
                                alt=""
                              />
                              <Box style={{ background: "#E2E8F0", borderBottomLeftRadius: "13px", borderBottomRightRadius: "13px", borderTopRightRadius: "13px", padding: "10px", maxWidth: "300px", wordWrap: "break-word" }}>
                                <Typography style={{ fontFamily: "MyFont", letterSpacing: "0.8px", color: "#0F172A", fontSize: "15px", fontWeight: "bold" }}>
                                  {element.attributes.sender.name}
                                </Typography>
                                <Typography style={{ color: "#0F172A", fontFamily: "MyFont", fontSize: "13px", letterSpacing: "0.8px" }}>
                                  {element.attributes?.content} <br />
                                  {element.attributes.media_url.url && <img src={element.attributes.media_url.url} style={{ width: "100px", height: "100px", marginTop: "5px" }} alt="" />}
                                </Typography>
                                {
                                  (element.attributes?.share?.media?.length || 0) > 0 && (
                                    <>
                                      <Box style={{ color: "#0F172A", padding: "10px", display: "flex", background: "#fff", justifyContent: "center", alignItems: "center", borderTopLeftRadius: "13px", borderBottomLeftRadius: "13px", borderBottomRightRadius: "13px", maxWidth: "300px", wordWrap: "break-word", fontFamily: "MyFont", fontSize: "13px", letterSpacing: "0.8px" }} onClick={this.handleRedirectHome.bind(this, element.attributes.share.id)}>
                                        {
                                          <img src={element.attributes.share.media[0].url} style={{ width: "40px", height: "40px", fontFamily: "MyFont", }} alt="" />

                                        }
                                        <Box>
                                          <Typography style={{ fontSize: "12px", fontFamily: "MyFont", color: "#0F172A", fontStyle: "normal", marginLeft: "8px" }}>

                                            {element.attributes.share.body}

                                          </Typography>
                                        </Box>

                                      </Box>
                                    </>
                                  )
                                }
                                {
                                  (element.attributes.share !== null && element.attributes.share.type === "comment") && (
                                    <Box style={{ padding: "10px", display: "flex", color: "#0F172A", justifyContent: "center", alignItems: "center", background: "white", borderTopLeftRadius: "13px", borderBottomLeftRadius: "13px", borderBottomRightRadius: "13px", maxWidth: "300px", wordWrap: "break-word", fontFamily: "MyFont", fontSize: "13px", letterSpacing: "0.8px" }}>

                                      <Typography style={{ fontFamily: "MyFont", letterSpacing: "0.8px" }}>{element.attributes.share.comment}</Typography>

                                    </Box>
                                  )
                                }
                              </Box>
                            </Box>

                            <Box style={{ marginLeft: "35px", fontFamily: "MyFont" }}>
                              <Typography style={{ fontFamily: "MyFont", letterSpacing: "0.8px", color: "#0F172A", fontSize: "11px" }}>
                                {moment(element.attributes.created_at).format("hh:mm A")}
                              </Typography>
                            </Box>


                          </>

                        )
    
                      }

                    </>

                  )
                }) : (
                  <>
                    <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "70vh" }}>
                      <Typography style={{ marginRight: "5px", letterSpacing: "0.9px", fontFamily: "MyFont", fontWeight: "bold" }}>No Chat</Typography>
                    </Box>

                  </>

                )

              }
      </>
    )
  }
  renderBlockUserDrawer = () => {
    const {  blockDrawer, currentUserInfo , reasonTitle } = this.state;
  
    const isReasonSelected = !!reasonTitle; 
    return (
      <Drawer
        anchor="right"
        open={blockDrawer}
        onClose={() => this.handleChangeBlockDrawer(false)}
        PaperProps={{
          style: {
            borderTopLeftRadius: "20px",
            width: "484px",
          },
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            borderBottom: "1.04px solid #CBD5E1",
          }}
        >
          <Box
            style={{ display: "flex", padding: "4% 0 0 4%", width: "350px" }}
          >
            <Typography
              style={{
                fontSize: "24px",
                fontWeight: 600,
                color: "#14362E",
                fontFamily: "MyFont",
                letterSpacing: "1px",
              }}
            >
              Block {currentUserInfo?.receiver?.name} 
            </Typography>
          </Box>
          <Box>
            <IconButton
              style={{ right: "10px", margin: "15px 5px" }}
              onClick={() => this.handleChangeBlockDrawer(false)}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <hr style={webStyles.horizontalLineDrawer} />
        <Box
          style={{
            marginTop: "15px",
            color: "#0F172A",
            fontSize: "16px",
            fontFamily: "MyFont",
            fontWeight: 500,
            letterSpacing: "0.05em",
            marginLeft: "10px",
          }}
        >
          Why are you blocking this user?
        </Box>
        <List>
          {configJSON.blockReasons.map((reason: any) => (
            <ListItem key={reason.id}              
              button
              onClick={() => this.handleReasonSelect(reason.title, reason.description)}
              style={{ backgroundColor: reason.title === reasonTitle ? "#D4E5DE" : "#FFF",
              }}
            >
              <ListItemText
                primary={
                  <Typography
                    style={{ fontFamily: "MyFont",
                      fontSize: "16px",  
                      fontWeight: 500,
                      letterSpacing: "0.05em",
                      color: reason.title === reasonTitle ? "#14362E" : "#0F172A", }}
                  >
                    {reason.title}
                  </Typography>
                }
                secondary={
                  <Box style={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography
                      style={{ fontWeight: 500,
                        fontSize: "14px",
                        fontFamily: "MyFont", 
                        color: reason.title === reasonTitle ? "#14362E" : "#0F172A",
                        letterSpacing: "0.05em",
                      }}
                    >
                      {reason.description}
                    </Typography>
                    {reason.title === reasonTitle && (
                      <Avatar src={checkCircle} style={{ width: "5%", height: "5%" }}  />
                    )}
                  </Box>
                } />
            </ListItem>
          ))}
        </List>
        <Box
          style={{
            width: "428px",
            height: "56px",
            position: "relative",
            bottom: 0,
            padding: "0 30px",
          }}
        >
          <Button
            variant="contained"
            fullWidth
            style={{
              backgroundColor: isReasonSelected ? "#14362E" : "#D4E5DE",
              borderRadius: "8px",
              height: "45px",
              color: isReasonSelected ? "white" : "#14362E",
              fontWeight: 700,
              fontFamily: "MyFont",
              letterSpacing: "1px",
              fontSize: "16px",
              textTransform: "capitalize",
            }}
            data-test-id={"continueBtn"}
            onClick={() => this.handleChangeContinueBlock(true)}
            disabled={!isReasonSelected}
          >
            Continue
          </Button>
        </Box>
      </Drawer>
    );
  };
  renderRemoveModal = () => {
    const { removeDrawer, currentUserInfo } = this.state;
    
    return (
      <Modal open={removeDrawer} onClose={() => this.handleChangeRemoveDrawer(false)}>
        <Paper
          style={{ ...webStyles.removeFromMyCircleModal, position: "absolute" }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" style={webStyles.modalTitle}>
              Remove from My Circle?
            </Typography>
            <Avatar src={currentUserInfo?.receiver?.profile_picture?.url}></Avatar>
            <Typography style={webStyles.modalUserName}>
              {currentUserInfo?.receiver?.name}
            </Typography>
 
            <Typography variant="body1" style={webStyles.modalContent}>
              Do you really want to remove {currentUserInfo?.receiver?.name} from your
              circle?
            </Typography>
          </Box>

          <Box style={webStyles.modalActions}>
            <Button data-test-id="cancel-btn-removeMyCircle"
              variant="contained"
              style={{
                ...webStyles.cancelButton,
                textTransform: "capitalize",
              }}
              onClick={() => this.handleChangeRemoveDrawer(false)}
            >
              Cancel
            </Button>
            <Button
              data-test-id="remove-btn-removeMyCircle"
              variant="outlined"
              style={{
                ...webStyles.removeButton,
                textTransform: "capitalize",
              }}
              onClick={() => this.handleRemoveUser()}
            >          
              Remove
            </Button>
          </Box>
        </Paper>
      </Modal>
    );
  };
  blockUserConfirmationModal = () => {
    const { continueBlock } = this.state;

    return (
      <Modal open={continueBlock} onClose={() => this.handleChangeContinueBlock(false)}>
        <Paper
          style={{ ...webStyles.continueBlockModal, position: "absolute", borderBottomRightRadius:"20px" }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign:"center",
            }}
          >
            <Typography variant="h6" style={webStyles.modalTitle}>
              Block User
            </Typography>
            <Typography style={webStyles.continueModalDescription}>
              We understand that sometimes you need to remove certain people from your circle, that's ok! If it's the right thing for you, then it's the right thing to do! And don't worry, we will keep this between us, they won't know you have blocked them.
            </Typography>
          </Box>

          <Box style={webStyles.continueBlockModalBox}>
            <Button fullWidth
              variant="contained"
              style={{
                ...webStyles.cancelBtnContinueModal,
                textTransform: "capitalize",
              }}
              data-test-id="cancel-btn-blockModal"
              onClick={() => this.handleChangeContinueBlock(false)}
            >
              Cancel
            </Button>
            <Button fullWidth
              data-test-id="confirm-btn-blockModal"
              variant="outlined"
              style={{
                ...webStyles.confirmButton,
                textTransform: "capitalize",
              }}
              onClick={() => this.handleBlockUser()}
            >
              Confirm
            </Button>
          </Box>
        </Paper>
      </Modal>
    );
  };
  groupchatDataDisplay() {
    return (
      <>
        <Container>

          <Box>
            <Box style={webStyles.profilechat as any}>
              <Box style={{ display: "flex", alignItems: "center", cursor: "pointer" }} onClick={this.handleInfodrawerOpen}>
                <i className="fa-solid fa-angle-left" style={{ marginRight: "5px", cursor: "pointer" }} onClick={this.goBack}></i>
                <img style={webStyles.profileImg} src={this.state.groupInfo.group_info?.group_profile_image.url ? this.state.groupInfo.group_info?.group_profile_image.url :addImg} alt="" />
                <Box>
                  <Typography style={{ fontFamily: "MyFont", fontWeight: "bold", fontSize: "16px", letterSpacing: "0.8px" }}>{this.state.groupInfo.group_info?.room_name} <img src={circleimg} alt="" /></Typography>
                  <Typography style={{ fontFamily: "MyFont", fontSize: "13px", letterSpacing: "0.8px" }}>{this.state.groupInfo.group_info?.number_of_participants} Participants</Typography>
                </Box>
              </Box>
              <Box onClick={this.editopen}>
                <EditIcon fontSize="small"/>
              </Box>
            </Box>
          </Box>
        
        </Container>
        <Box style={webStyles.borderBottomstyle as any}></Box>

        <Container>
          <Box >
            <Box style={{ height: "63vh", overflow: "auto", padding: "10px" }}>
            {this.insideGroupChatDataDisplay()}
            </Box>
            <Popover
              open={Boolean(this.state.pickerGroupAnchor)}
              anchorEl={this.state.pickerAnchor}
              onClose={() => this.changeGroupPickerAnchor(null)}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Picker style={{ width: "100%", marginTop: "20px" }} onEmojiClick={this.Emojiclickgroup} />
            </Popover>
            <Box style={{
              marginTop: "20px", display: "flex", alignItems: "center", border: "1px solid #CBD5E1", padding: "12px",
              borderRadius: "10px",
              justifyContent:'space-between'
            }}>
              <Box style={{ display:'flex', alignItems:'center', flex: '1',gap:'15px' }}>
                  <label htmlFor="usefile">
                    <AttachFileIcon  style={{scale:'0.8', cursor: "pointer" }}/>
                  </label>
                  <EmojiEmotionsIcon onClick={this.openGroupEmoji} style={{scale:'0.8', cursor: "pointer" }} />
                  <input type="file" name="" style={{ display: "none" }} id="usefile" onChange={this.handleFileChangegroup} />
                  <textarea style={{ border: "none", outline: "none", width: "85%",wordBreak:"break-word", height:"auto",resize:"none", overflowY: 'auto' }} value={this.state.message} onChange={this.handleMessageChange}  data-test-id='messageBlock' rows={1} placeholder={"Message "+ this.state.currentUserInfo?.receiver?.name} />
              </Box>
              <Box style={{ width:'40px', height:'40px', cursor: "pointer",padding:"1px", background: '#14362E', color: 'white', borderRadius: '8px', display:'flex', justifyContent:'center', alignItems:'center'}} 
                data-test-id='sendMessageBtn'
                onClick={this.state.message || this.state.uploadedImages.length > 0
                  ? this.handleSendMessageforgroup.bind(this)
                  : () => { }}
                >
                  <SendIcon fontSize="small" style={{scale:"0.8", cursor: 'pointer'}}/>
              </Box>
            </Box>
            <Box style={{display:'flex', marginTop:'10px' }}>
                {this.state.uploadedImages.length > 0 && <img style={{  height: "61px", width: "61px", marginTop: "10px" }} src={this.state.uploadedImages[0].base64convert} />}
              </Box>
            <Drawer style={webStyles.drawer as any} anchor="right" open={this.state.open} onClose={this.toggleDrawer.bind(this, false)} >
              {this.groupInfosidebaar()}
            </Drawer>

            <Drawer style={webStyles.drawer as any} anchor="right" open={this.state.openmember} onClose={this.toggleDrawermember.bind(this, false)} >
              {this.memberSiderbaar()}
            </Drawer>

            <Drawer style={webStyles.drawer as any} anchor="right" open={this.state.reportopen} onClose={this.toggleDrawermemberreport.bind(this, false)} >
              {this.ReportSidebaar()}
            </Drawer>

            <Drawer style={webStyles.drawer as any} anchor="right" open={this.state.editdrawer}  onClose={this.drawerClose}>
              {this.EditSidebaar()}
            </Drawer>

            <Modal
              open={this.state.modalopen}
              onClose={this.handleClose}
              aria-labelledby="child-modal-title"
              aria-describedby="child-modal-description"
            >
              <Box style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 300,
                padding: "10px",
                border: "none",
                background: "#fff",
                outline: "none",
                borderRadius: "10px"
              }}>
                <Typography style={{ color: "#D1584C", textAlign: "center", fontSize: "40px", marginTop: "10px" }}><i className="fa-solid fa-ban"></i></Typography>
                <h2 id="child-modal-title" style={{ textAlign: "center", fontFamily: "MyFont", fontWeight: "bold", letterSpacing: "0.8px", fontSize: "22px" }}>Are you sure you want to leave this group?</h2>
                <p id="child-modal-description" style={{ textAlign: "center", fontFamily: "MyFont", letterSpacing: "0.8px", fontSize: "15px" }}>
                  If you leave this group you cannot go back unless the group admin adds you back
                </p>
                <Button onClick={this.handleClose} style={{ width: "100%", background: "#14362E", textTransform: "capitalize", color: "#fff", cursor: "pointer" }} variant="contained">Okay</Button>
              </Box>
            </Modal>
          </Box>
        </Container>
      </>
    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <AppHeader />
        <Container>
          <Box key={1} sx={{marginTop: {xs: '0px', md: '84px'}}} style={{ display: "flex", flexDirection: "column", alignItems: "center", background: "#F8FAFC" }}>

            <Card style={{ maxWidth: 750, marginBottom: "25px", width: "100%", fontFamily: "MyFont", minHeight: "100vh", borderRadius: "12px", padding: "10px", boxSizing: 'border-box' }}>

              {
                this.state.chattabs == "chats" ? (
                  <>
                    {this.chatDatadisplay()}
                  </>
                ) : (
                  <>
                    {this.groupchatDataDisplay()}
                  </>
                )
              }

            </Card>
          </Box>
          <Toaster />
        </Container>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const BoxMain = styled(Box)({

  width: "400px",
  "@media(max-width: 600px)": {
    width: '100vw',
  },
})
const webStyles = {
  borderBottomstyle: {
    border: "1px solid #E2E8F0"
  },
  profilechat: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px"
  },
  profileImg: {
    borderRadius: "50%",
    width: "50px",
    height: "50px",
    marginRight: "5px"
  },
  drawer: {
    position: "relative",
    width: "500px",
    display: "block",
    '@media (min-width: 600px)': {
      display: "none",
    },
    "& .MuiDrawer-paper": {
      boxSizing: "border-box",
      width: "240px",
    },
  },
  searchbox: {
    marginTop: "20px",
    fontFamily: "MyFont",
    background: "white",
    border: "1px solid #CBD5E1",
    borderRadius: "10px",
    color: "#CBD5E1",
    height: "40px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down('sm')]: {
      display: "none",
    },
  },
  inputbox: {
    border: "none",
    outline: "none",
    fontFamily: "MyFont",
    letterSpacing: "1px"
  },
  chatMenuItem : {
    fontFamily: 'MyFont',
    fontSize: '14px',
    color: '#0F172A',
  },
  horizontalLine: {
    border: "none",
    borderBottom: "1px solid #CBD5E1",
    margin: "0",
  },
  removeFromMyCircleModal: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: "white",
    padding: 20,
    borderRadius: 8,
  },
  modalTitle: {
    marginBottom: 15,
    fontFamily: "'MyFont', 'Halyard Display'",
    color: "#14362E",
    fontSize: "24px",
    fontWeight: 500,
    letterSpacing: "0.05em",
  },
  modalContent: {
    marginTop: 20,
    marginBottom: 20,
    color: "#0F172A",
    fontSize: "16px",
    fontWeight: 500,
    letterSpacing: "0.05em",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  modalActions: {
    display: "flex",
    justifyContent: "center",
    gap: 8,
  },
  cancelButton: {
    backgroundColor: "#14362E",
    color: "white",
    borderRadius: "7px",
    letterSpacing: "0.05em",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  removeButton: {
    backgroundColor: "white",
    borderRadius: "7px",
    color: "#14362E",
    borderColor: "#14362E",
    letterSpacing: "0.05em",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  modalUserName: {
    color: "#0F172A",
    letterSpacing: "0.05em",
    fontSize: "16px",
    fontWeight: "bolder",
    marginTop: "5px",
    fontFamily: "'MyFont', 'Halyard Display'",
  },

  horizontalLineDrawer: {
    border: "none",
    borderBottom: "0.5px solid #CBD5E1",
    margin: "0",
  },
  mainBox: {
    '@media (max-width: 560px)': {
      width: "100%",
      height: "100%"
    }
  },
  titleHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "40%",
    padding: "10px",
    margin: "14px 0 32px 0",
    '@media (max-width: 560px)': {
      gap: "30%",
    },
  },
  subTitle: {
    width:"auto",
    marginTop: "20px",
    marginBottom: "30px",
    textAlign: "center",
    '@media (max-width: 560px)': {
    width: "256px",
    }
  },
  refreshBtn: {
    width:"100%",
    '@media (max-width: 560px)': {
    width: "80%",
    }
  },
  outerBox: {
    width: "100%",
    height: "100vh",
    minHeight: "100vh",
    backgroundColor: "#F8FAFC",
    // backgroundColor: "pink",
  },
  parentOfMainButtons: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "20px",
    marginBottom: "20px",
    width: "83%",
    backgroundColor: "#F8FAFC",
    borderRadius: "20px",
  },
  viewsBox: {
    marginLeft: "5%",
    marginRight: "5%",
  },
  searchBox: {
    marginLeft: "3%",
    marginRight: "3%",
  },
  usernameFirstLetter: {
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  userNameStyle: {
    color: "#0F172A",
    letterSpacing: "0.05em",
    fontSize: "16px",
    fontWeight: "bolder",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  position: {
    color: "#64748B",
    fontSize: "12px",
    letterSpacing: "0.05em",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  mutualFriends: {
    letterSpacing: "0.05em",
    color: "#73A58E",
    fontSize: "12px",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  duration: {
    fontSize: "12px",
    letterSpacing: "0.05em",
    color: "#64748B",
    marginTop: "15px",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  menuItemConnections: {
    color: "#0F172A",
    fontSize: "16px",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  menuItemBlocked: {
    color: "#D1584C",
    fontSize: "16px",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  acceptBtn: {
    marginRight: 10,
    backgroundColor: "#14362E",
    color: "#ffffff",
    borderRadius: "7px",
    fontWeight: 500,
    fontSize: "16px",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  cancelBtn: {
    borderRadius: "7px",
    borderColor: "73A58E",
    color: "#14362E",
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  invitedBtn: {
    backgroundColor: "#D4E5DE",
    color: "#14362E",
    borderRadius: "7px",
    fontWeight: 500,
    fontSize: "16px",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  cancelInviteStyle: {
    fontFamily: "'MyFont', 'Halyard Display'",
    top:"20%"
  },
  unblockBtn: {
    color: "#14362E",
    fontSize: "16px",
    borderColor: "#14362E",
    borderRadius: "7px",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  modalPosition: {
    color: "#64748B",
    fontSize: "12px",
    letterSpacing: "0.08em",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  continueBlockModal: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 450,
    backgroundColor: "white",
    padding: 20,
    borderRadius: 8,
  },
  continueModalDescription: {
    color: "#0F172A",
    fontSize: "16px",
    letterSpacing: "0.05em",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  continueBlockModalBox: {
    display: "flex",
    justifyContent: "space-between",
    gap: 10,
    marginTop:"20px"
  },
  cancelBtnContinueModal: {
    backgroundColor: "#14362E",
    color: "white",
    borderRadius: "7px",
    letterSpacing: "0.05em",
    fontFamily: "'MyFont', 'Halyard Display'",
  },
  confirmButton: {
    backgroundColor: "white",
    borderRadius: "7px",
    color: "#14362E",
    borderColor: "#14362E",
    letterSpacing: "0.05em",
    fontFamily: "'MyFont', 'Halyard Display'",
  },



};

// Customizable Area End
