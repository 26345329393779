import React from "react";

// Customizable Area Start
import { Container, Box, Button, Grid, Typography, TextField, InputLabel, InputAdornment, IconButton, Checkbox, Avatar, Drawer, List, ListItem, ListItemText, Radio, FormControlLabel, Dialog, DialogContent } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { women, weblogo, newlogo, newstepper, Success, Warning, mobileBgLogo, DefaultAvatar, finalmobilelogo } from "./assets";
import { Link } from "react-router-dom";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { Visibility, VisibilityOff } from "@material-ui/icons";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from "@material-ui/lab";
import SearchIcon from '@material-ui/icons/Search';
import TermsConditions from "../../termsconditions/src/TermsConditions.web";
const navigation = require("react-navigation");

const theme = createTheme({
  palette: {
    primary: {
      main: "#14362E",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController";

export default class EmailAccountRegistrationBlock extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Bind the handleFocus method
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    // Customizable Area End
  }
  // Customizable Area Start
  mobileBtnView() {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Box
            style={{
              position: "absolute",
              bottom: "20%",
              left: "50%",
              transform: "translate(-50%, 50%)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={finalmobilelogo}
              style={{ width: "250.65px", height: "70.28px" }}
            />
            <Box style={{ width: "323px", marginTop: "28px" }}>
              <Link to='/EmailAccountLoginBlock' style={{ textDecoration: "none" }}>
                <Button
                  id="mobile-login-button"
                  variant="contained"
                  fullWidth
                  style={{
                    backgroundColor: "white",
                    color: "#059669",
                    borderRadius: "8px",
                    height: "45px",
                    fontFamily: "MyFont",
                    letterSpacing: "0.8",
                    fontWeight: 700,
                    fontSize: "16px",
                    textTransform: "none"
                  }}
                >
                  Log in
                </Button>
              </Link>
            </Box>
            <Box style={{ width: "100%", marginTop: "16px" }}>
              <Button
                data-test-id="mobileSignUpBtn"
                variant="outlined"
                style={{
                  color: "white",
                  borderColor: "white",
                  borderRadius: "8px",
                  height: "45px",
                  letterSpacing: "0.8",
                  width: "100%",
                  fontFamily: "MyFont",
                  fontWeight: 700,
                  fontSize: "16px",
                  textTransform: "inherit"
                }}
                onClick={() => this.handleMobileSignUpClick()}
              >
                Sign up
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>

    )
  }

  webBtnView() {
    return (
      <Grid container style={{ height: "100vh" }}>
        <Grid item md={8}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src={weblogo}
              style={{ width: "250.65px", height: "70.28px" }}
            />

            <Box style={{ width: "323px", marginTop: "28px" }}>
              <Link to='/EmailAccountLoginBlock' style={{ textDecoration: "none" }}>
                <Button
                  id="web-login-button"
                  variant="contained"
                  fullWidth
                  style={{
                    backgroundColor: "#14362E",
                    color: "white",
                    borderRadius: "8px",
                    height: "45px",
                    fontFamily: "MyFont",
                    letterSpacing: "0.8",
                    fontWeight: 700,
                    fontSize: "16px",
                    textTransform: "none"
                  }}
                >
                  Log in
                </Button>
              </Link>
            </Box>
            <Box style={{ width: "323px", marginTop: "16px" }}>
              <Button
                data-test-id="webSignUpBtn"
                variant="outlined"
                fullWidth
                style={{
                  color: "#14362E",
                  borderColor: "#059669",
                  borderRadius: "8px",
                  height: "45px",
                  fontFamily: "MyFont",
                  letterSpacing: "0.8",
                  fontWeight: 700,
                  fontSize: "16px",
                  textTransform: "inherit"
                }}
                onClick={() => this.handleSignUpClick()}
              >
                Sign up
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid style={{ position: "relative" }} item xs={12} md={4}>
          <img src={women} alt="Women"
            style={{
              width: "100%",
              objectFit: "contain",
              position: "absolute",
            }}
          />
        </Grid>
      </Grid>
    )

  }

  handleAlert = () => {
    const {
      isValidEmailField,
      isValidPasswordField,
      isValidLastNameField,
      isValidFirstNameField,
      countryError,
      error,
      isValidPhoneNumberField,
      erroremail,
      errorphonenum,
      errorCheckBox,
      formSubmitted,
      finalImg,
    } = this.state;

    if (!(
      isValidEmailField ||
      isValidPasswordField ||
      isValidLastNameField ||
      isValidFirstNameField ||
      countryError ||
      isValidPhoneNumberField ||
      erroremail ||
      errorphonenum ||
      errorCheckBox ||
      error ||
      (formSubmitted && finalImg === "")
    )) {
      return null;
    }

    if (isValidEmailField || isValidPasswordField || isValidLastNameField || isValidFirstNameField || countryError || isValidPhoneNumberField) {
      return (
        <Alert severity="error" onClose={this.alertclose} style={{ alignSelf: "center", alignItems: "center", padding: "6px 14px" }}>
          Please fix the errors highlighted in the red below.
        </Alert>
      );
    }

    if (erroremail) {
      return (
        <Alert severity="error" onClose={this.alertclose} style={{ alignSelf: "center", alignItems: "center", padding: "6px 14px" }}>
          Email Already Exists
        </Alert>
      );
    }

    if (!isValidPhoneNumberField && errorphonenum) {
      return (
        <Alert severity="error" onClose={this.alertclose} style={{ alignSelf: "center", alignItems: "center", padding: "6px 14px" }}>
          Invalid Phone Number
        </Alert>
      );
    }

    if (formSubmitted && finalImg === "") {
      return (
        <Alert severity="error" onClose={this.alertclose} style={{ alignSelf: "center", alignItems: "center", padding: "6px 14px" }}>
          Profile Image Is Required
        </Alert>
      );
    }

    if (errorCheckBox) {
      return (
        <Alert severity="error" onClose={this.alertclose} style={{ alignSelf: "center", alignItems: "center", padding: "6px 14px" }}>
          To sign up, please agree with Privacy Policy, GDPR and Terms & Conditions.
        </Alert>
      );
    }

    if (error) {
      return (
        <Alert severity="error" onClose={this.alertclose} style={{ alignSelf: "center", alignItems: "center", padding: "6px 14px" }}>
          {error}
        </Alert>
      );
    }

    return null;
  };

  isFirstNameError = () => {
    return !this.state.errorFirstNameFlag ? this.state.isValidFirstNameField : !this.state.isValidFirstName;
  };
  isLastNameError = () => {
    return !this.state.errorLastNameFlag ? this.state.isValidLastNameField : !this.state.isValidLastName;
  };
  isEmailError = () => {
    return !this.state.errorEmailFlag ? this.state.isValidEmailField : !this.state.isValidEmail;
  };

  handleFocus() {
    this.setState({ isFocused: true });
  }

  handleBlur() {
    this.setState({ isFocused: false });
  }

  signupWeb() {
    const { options } = this.state;
    return (
      <Grid container style={{ height: "0px" }}>
        <Grid item md={8} style={{ paddingInline: "80px" }}>
          <Grid container spacing={4}>
            <Grid item xs={12}
              style={{
                marginTop: "50px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "25px"
              }}>
              <img
                src={newlogo}
                style={{ width: "249.88px", height: "45.59px" }}
              />
              <Box>
                <img
                  src={newstepper}
                  style={{ height: "34.59px", width: "249.88px" }}
                />
              </Box>
              <Typography style={{ fontSize: "20px", color: "#14362E", fontFamily: "MyFont", letterSpacing: "1.3", fontWeight: 800 }}>Personal Information</Typography>
            </Grid>
            <Grid item xs={12} style={{ display: "flex", justifyContent: "center", flexDirection: "column", gap: "7px" }}>
              {this.handleAlert()}
            </Grid>
            <Grid item xs={12} style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
              <Box style={{ marginBottom: "16px" }}>
                <Avatar
                  src={this.state.selectedImage || DefaultAvatar} style={{ height: "100px", width: "100px", border: this.state.formSubmitted && this.state.finalImg == "" ? "1px solid red" : "" }}
                />
              </Box>
              <Box style={{ display: "flex", justifyContent: "center" }}>
                <Typography
                  component="label"
                  htmlFor="fileInput"
                  style={{ fontWeight: 700, fontSize: "14px", marginTop: "10px", color: "#334155", fontFamily: "MyFont", letterSpacing: "1.3" }}
                >
                  Profile Picture
                </Typography>
              </Box>

              <Box>
                <div>
                  <label htmlFor="profilePicture">
                    <Button
                      data-test-id="webImgUploadBtn"
                      variant="outlined"
                      color="primary"
                      style={{
                        width: "112px",
                        borderColor: "#14362E",
                        color: "#14362E",
                        marginTop: "10px",
                        borderRadius: "8px",
                        fontWeight: 700,
                        fontSize: "14px",
                        textTransform: "capitalize",
                        position: "relative",
                        cursor: "pointer",
                      }}
                    >
                      Upload
                      <input
                        type="file"
                        data-test-id="webImgUploadInput"
                        onChange={this.handleFileChange}
                        accept="image/*"
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          opacity: 0,
                          width: "100%",
                          height: "100%",
                          cursor: "pointer",
                          zIndex: 1
                        }}
                      />
                    </Button>
                  </label>
                </div>
              </Box>

            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={6}>
                  <InputLabel htmlFor="firstName" style={{ fontSize: "16px", fontWeight: 700, color: "#334155", fontFamily: "MyFont", letterSpacing: "1.4" }}>First Name</InputLabel>
                  <TextField
                    fullWidth
                    data-test-id="webfirstName"
                    type="text"
                    variant="outlined"
                    error={this.isFirstNameError()}
                    value={this.state.kellaFirstName}
                    onChange={(e) => this.handleFirstName(e.target.value.trim())}
                    placeholder="Enter First Name"
                    InputProps={{
                      style: {
                        marginTop: "5px",
                        borderRadius: "8px",
                        borderColor: "#CBD5E1",
                      }
                    }} />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <InputLabel htmlFor="lastName" style={{ fontSize: "16px", fontWeight: 700, color: "#334155", fontFamily: "MyFont", letterSpacing: "1.4" }}>Last Name</InputLabel>
                  <TextField
                    fullWidth
                    data-test-id="weblastName"
                    variant="outlined"
                    error={this.isLastNameError()}
                    value={this.state.kellaLastName}
                    onChange={(e) => this.handleLastName(e.target.value.trim())}
                    placeholder="Enter Last Name"
                    InputProps={{
                      style: {
                        marginTop: "5px",
                        borderRadius: "8px",
                        borderColor: "#CBD5E1",
                      },
                    }} />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={6}>
                  <InputLabel htmlFor="phoneNumber" style={{ fontSize: "16px", fontWeight: 700, color: "#334155", fontFamily: "MyFont", letterSpacing: "1.4" }}>Phone number</InputLabel>
                  <PhoneInput
                    data-test-id="txtWebPhoneInput"
                    specialLabel=""
                    value={this.state.kellaPhoneNumber}
                    country={'ie'}
                    onChange={kellaPhoneNumber => this.setState({ kellaPhoneNumber, isValidPhoneNumberField: false })}
                    inputProps={{
                      style: {
                        border: `1px solid ${this.state.isValidPhoneNumberField || this.state.errorphonenum ? "red" : "#CACACA"}`,
                        marginTop: "5px",
                        borderRadius: "8px",
                        width: "100%",
                        boxShadow: this.state.isFocused
                          ? (this.state.isValidPhoneNumberField || this.state.errorphonenum ? "0 0 0 1px red" : "0 0 0 2px #000")
                          : "0 0 0 2px transparent",
                      },
                      onBlur: this.handleBlur,
                      onFocus: this.handleFocus, // Use the bound method
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <InputLabel htmlFor="country" style={{ fontSize: "16px", fontWeight: 700, color: "#334155", fontFamily: "MyFont", letterSpacing: "1.4" }}>Country</InputLabel>
                  <TextField
                    id="country"
                    fullWidth
                    placeholder="Select an option"
                    error={this.state.countryError}
                    variant="outlined"
                    data-test-id="countryWeb"
                    value={this.state.selectedCountry ? options[this.state.selectedCountry].name : ''}
                    onClick={this.toggleWebDrawer(true)}
                    InputProps={{
                      style: { marginTop: "5px", borderRadius: "8px" },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={this.toggleWebDrawer(true)}>
                            <ArrowDropDownIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
              </Grid>
              <Drawer anchor="right" open={this.state.webDrawerOpen} onClose={this.toggleWebDrawer(false)}
                PaperProps={{
                  style: {
                    borderTopLeftRadius: '20px',
                  },
                }}
              >
                <Box style={{ display: 'flex', justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                  <Box style={{ display: "flex", padding: "10px" }}>
                    <Typography style={{ fontSize: "24px", fontWeight: 600, color: "#14362E", fontFamily: "MyFont", letterSpacing: "1.4" }}>Country</Typography>
                  </Box>
                  <Box>
                    <IconButton
                      style={{ right: '10px' }}
                      onClick={this.toggleWebDrawer(false)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </Box>

                <Box style={{ display: "flex", padding: "10px" }}>
                  <TextField
                    fullWidth
                    placeholder="Search countries"
                    data-test-id="web-search"
                    variant="outlined"
                    value={this.state.searchQuery}
                    onChange={this.handleSearch}
                    InputProps={{
                      style: { borderRadius: "8px", marginTop: "5px", },
                      endAdornment: (
                        this.state.searchQuery && (
                          <InputAdornment position="end">
                            <IconButton onClick={this.searchQueryfunc}>
                              <CloseIcon />
                            </IconButton>
                          </InputAdornment>
                        )
                      ),
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box style={{ overflow: 'auto', marginTop: "30px" }}>
                  <List>
                    {Object.keys(options).map((code) => {
                      const searchQuery = this.state.searchQuery.toLowerCase();
                      const countryName = options[code].name.toLowerCase();
                      if (!searchQuery || countryName.includes(searchQuery)) {
                        return (
                          <ListItem
                            key={code}
                            button
                            onClick={() => {
                              this.handleChangeCountry({ target: { value: code } });
                            }}
                          >
                            <Box
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '100%',
                              }}
                            >
                              <Box>
                                <ListItemText primary={options[code].name} />
                              </Box>
                              <Box>
                                <Radio
                                  data-test-id='webRadioBtn'
                                  checked={this.state.selectedCountry === code}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                  onChange={() => {
                                    this.handleChangeCountry({ target: { value: code } });
                                  }}
                                  style={{ color: "green" }}
                                />
                              </Box>
                            </Box>
                          </ListItem>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </List>
                </Box>

              </Drawer>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <InputLabel htmlFor="email" style={{ fontSize: "16px", fontWeight: 700, color: "#334155", fontFamily: "MyFont", letterSpacing: "1.4" }}>Email</InputLabel>
                  <TextField
                    type="email"
                    data-test-id="txtWebInputEmail"
                    error={this.isEmailError()}
                    placeholder="example@gmail.com"
                    fullWidth
                    value={this.state.kellaEmail}
                    onChange={(e) => this.setEmail(e.target.value.trim())}
                    variant="outlined"
                    InputProps={{
                      style: { marginTop: "5px", borderRadius: "8px" }
                    }}
                  />

                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel htmlFor="password" style={{ fontSize: "16px", color: "#334155", fontWeight: 700, fontFamily: "MyFont", letterSpacing: "1.4" }}>Password</InputLabel>
                  <TextField
                    data-test-id="pwdWebInput"
                    type={this.state.enablePassword ? "password" : "text"}
                    placeholder="Your password"
                    error={!this.state.errorPasswordFlag ? this.state.isValidPasswordField : (!this.state.isOneNumber && this.state.isValidLength && this.state.isSpecialcharacter)}
                    value={this.state.kellaPassword}
                    fullWidth
                    variant="outlined"
                    onChange={(e) => this.setPassword(e.target.value.trim())}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end">
                          <IconButton
                            onClick={this.handleClickShowPassword}
                            aria-label="toggle password visibility"
                            edge="end"
                            data-test-id="showPass"
                          >
                            {this.state.enablePassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                      style: { marginTop: "5px", borderRadius: "8px" }
                    }}
                  />
                  {this.state.passwordError && <div style={{ color: 'red' }}>{this.state.passwordError}</div>}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ display: "flex", alignItems: "flex-start", gap: "2px" }}>
              {this.passwordRequirementFormate()}
            </Grid>
            <Grid item xs={12} style={{ display: "flex", alignItems: "baseline", gap: "5px" }}>
              <Box style={webStyle.CustomCheckbox}>
                <Checkbox checked={localStorage.getItem("countSet") === "true"} style={{ color: "#73A58E" }} data-test-id="handleTermsConditionCheckedWeb" onClick={this.acceptPrivacyPollicy} />
              </Box>
              <Box>
                <Typography style={{ fontWeight: 500, fontSize: "14px", fontFamily: "MyFont", letterSpacing: "1px" }}>
                  <Box
                    style={{ cursor: "pointer" }}
                    data-test-id="handleTermsCondsOpenWeb"
                    onClick={this.handleTermsCondsOpen}>
                    By signing up you agree to our <span style={{ fontWeight: 700, color: "#14362E" }}>Terms & Conditions, GDPR</span>, and our <span style={{ fontWeight: 700, color: "#14362E" }}>Privacy Policy</span>
                  </Box>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Dialog id="model_add" PaperProps={{ style: { borderRadius: '0 24px 0 0' } }} maxWidth="sm"
                open={this.state.showModel} onClose={this.handleDialogClose} >
                <Box style={webStyle.termsWrapper} >
                  <CloseIcon
                    data-test-id="handleDialogCloseWeb"
                    onClick={this.handleDialogClose}
                  /></Box>
                <DialogContent style={{ padding: 0 }}>
                  <TermsConditions handleDialogClose={this.handleDialogClose} id={"TermsConditions"} navigation={navigation} />
                </DialogContent>
              </Dialog>
            </Grid>
            <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
              <Box style={{ width: "323px" }}>
                <Button
                  variant="contained"
                  data-test-id="web-login-button"
                  fullWidth
                  style={{
                    backgroundColor: "#14362E",
                    borderRadius: "8px",
                    height: "45px",
                    color: "white",
                    fontWeight: 700,
                    fontFamily: "MyFont",
                    letterSpacing: "1px",
                    fontSize: "16px",
                    textTransform: "capitalize",

                  }}
                  onClick={this.handleButtonClick}
                >
                  Next
                </Button>
              </Box>
            </Grid>

            <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
              <Box>
                <Typography
                  style={{
                    fontWeight: 400,
                    fontSize: "16px",
                    fontFamily: "MyFont",
                    letterSpacing: "1px"
                  }}
                >
                  Already have an account?
                  <Box
                    data-test-id='already-have-aacount'
                    component={'span'}
                    onClick={this.alreadyHaveAccount}
                    style={{ fontSize: "16px", fontWeight: 700, color: "#14362E", textDecoration: "none", marginLeft: "2px", cursor: "pointer" }}
                  >
                    Log in
                  </Box>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={4} style={{ position: 'fixed', right: '0', height: '100vh' }}>
          <img
            src={women}
            alt="Women"
            style={{ maxWidth: "100%", width: "100%", height: "100%" }}
          />
        </Grid>
      </Grid>
    )
  }

  passwordRequirementFormate() {
    return (
      <Box style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
        {this.state.errorConfirmPasswordFlag && <>
          <Box style={webStyle.errorBox}>
            {this.state.isOneCapital ?
              <>
                <Box><img src={Success} alt="warningIcon" style={webStyle.imgSize} /></Box>
                <Box><Typography style={webStyle.passwordSuccessTypo}>At least one capital letter</Typography></Box>
              </>
              :
              <>
                <Box><img src={Warning} alt="warningIcon" style={webStyle.imgSize} /></Box>
                <Box><Typography style={webStyle.passwordSuccessTypoError}>At least one capital letter</Typography></Box>
              </>
            }
          </Box>
          <Box style={webStyle.errorBox}>
            {this.state.isOneLower ?
              <>
                <Box><img src={Success} alt="warningIcon" style={webStyle.imgSize} /></Box>
                <Box><Typography style={{ fontFamily: "MyFont", letterSpacing: "0.8", fontSize: "15px", fontWeight: "400" }}>At least one lowercase letter</Typography></Box>
              </>
              :
              <>
                <Box><img src={Warning} alt="warningIcon" style={webStyle.imgSize} /></Box>
                <Box><Typography style={{ fontFamily: "MyFont", letterSpacing: "0.8", fontSize: "15px", fontWeight: "400" }}>At least one lowercase letter</Typography></Box>
              </>
            }
          </Box>
          <Box style={webStyle.errorBox}>
            {this.state.isOneNumber ?
              <>
                <Box><img src={Success} alt="warningIcon" style={webStyle.imgSize} /></Box>
                <Box><Typography style={webStyle.passwordSuccessTypo}>At least one number</Typography></Box>
              </>
              :
              <>
                <Box><img src={Warning} alt="warningIcon" style={webStyle.imgSize} /></Box>
                <Box><Typography style={webStyle.passwordSuccessTypoError}>At least one number</Typography></Box>
              </>
            }
          </Box>
          <Box style={webStyle.errorBox}>
            {this.state.isValidLength ?
              <>
                <Box><img src={Success} alt="warningIcon" style={webStyle.imgSize} /></Box>
                <Box><Typography style={webStyle.passwordSuccessTypo}>Minimum character length is 8 characters</Typography></Box>
              </>
              :
              <>
                <Box><img src={Warning} alt="warningIcon" style={webStyle.imgSize} /></Box>
                <Box><Typography style={webStyle.passwordSuccessTypoError}>Minimum character length is 8 characters</Typography></Box>
              </>
            }
          </Box>
          <Box style={webStyle.errorBox}>
            {this.state.isSpecialcharacter ?
              <>
                <Box><img src={Success} alt="warningIcon" style={webStyle.imgSize} /></Box>
                <Box><Typography style={webStyle.passwordSuccessTypo}>At least One Special character</Typography></Box>
              </>
              :
              <>
                <Box><img src={Warning} alt="warningIcon" style={webStyle.imgSize} /></Box>
                <Box><Typography style={webStyle.passwordSuccessTypoError}>At least One Special character</Typography></Box>
              </>
            }
          </Box>
        </>}
        {this.state.errorEmailFlag && <>
          <Box style={webStyle.errorBox}>
            {this.state.isValidEmail ?
              <>
                <Box><img src={Success} alt="warningIcon" style={webStyle.imgSize} /></Box>
                <Box><Typography style={webStyle.passwordSuccessTypo}>Valid email address</Typography></Box>
              </>
              :
              <>
                <Box><img src={Warning} alt="warningIcon" style={webStyle.imgSize} /></Box>
                <Box><Typography style={webStyle.passwordSuccessTypoError}>Invalid email address</Typography></Box>
              </>
            }
          </Box></>}
      </Box>)
  }
  signupMobile() {
    const { options } = this.state;
    return (
      <Container style={{ display: "flex", justifyContent: "center" }}>
        <Grid item container xs={12} style={{ height: "0px", padding: '0 20px' }} spacing={4}>
          <Grid item xs={12}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "25px",
              marginTop: "50px",
              alignItems: "center",
            }}>
            <img
              src={newlogo}
              style={{ height: "45.59px", width: "249.88px" }}
            />
            <Box>
              <img
                src={newstepper}
                style={{ height: "34.59px", width: "249.88px" }}
              />
            </Box>
            <Typography style={{ fontSize: "20px", fontWeight: 800, color: "#14362E" }}>Personal Information</Typography>
          </Grid>
          <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
            {this.handleAlert()}
          </Grid>
          <Grid item xs={12} style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
            <Box style={{ marginBottom: "16px" }}>
              <Avatar
                src={this.state.selectedImage || DefaultAvatar} style={{ width: "100px", height: "100px", border: this.state.formSubmitted && this.state.finalImg == "" ? "1px solid red" : "" }}
              />
            </Box>
            <Box style={{ display: "flex", justifyContent: "center" }}>
              <Typography
                htmlFor="fileInput"
                component="label"
                style={{ fontWeight: 700, marginTop: "10px", fontSize: "14px", color: "#334155" }}
              >
                Profile Picture
              </Typography>
            </Box>
            <Box>
              <div>
                <label htmlFor="profilePicture">
                  <Button
                    variant="outlined"
                    color="primary"
                    data-test-id="mobileImgUploadBtn"
                    style={{
                      width: "112px",
                      marginTop: "10px",
                      borderColor: "#14362E",
                      borderRadius: "8px",
                      color: "#14362E",
                      fontWeight: 700,
                      fontSize: "14px",
                      fontFamily: "MyFont",
                      textTransform: "capitalize"
                    }}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      style={{ position: "absolute", left: 0, top: 0, opacity: 0, width: "100%", height: "100%", cursor: "pointer" }}
                      onChange={this.handleFileChange}
                    />
                    Upload
                  </Button>
                </label>
              </div>
            </Box>
          </Grid>

          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="firstName" style={{ fontSize: "16px", fontWeight: 700, color: "#334155" }}>First Name</InputLabel>
              <TextField
                data-test-id="mobilefirstName"
                variant="outlined"
                error={this.isFirstNameError()}
                fullWidth
                type="text"
                value={this.state.kellaFirstName}
                placeholder="Enter First Name"
                onChange={(e) => this.handleFirstName(e.target.value.trim())}
                InputProps={{
                  style: {
                    marginTop: "5px",
                    borderRadius: "8px",
                    borderColor: "#CBD5E1"
                  }
                }} />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="lastName" style={{ fontSize: "16px", fontWeight: 700, color: "#334155" }}>Last Name</InputLabel>
              <TextField
                fullWidth
                type="text"
                data-test-id="mobilelastName"
                variant="outlined"
                error={this.isLastNameError()}
                value={this.state.kellaLastName}
                placeholder="Enter Last Name"
                onChange={(e) => this.handleLastName(e.target.value.trim())}
                InputProps={{
                  style: {
                    marginTop: "5px",
                    borderRadius: "8px",
                    borderColor: "#CBD5E1"
                  }
                }} />
            </Grid>

            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="phoneNumber" style={{ fontSize: "16px", fontWeight: 700, color: "#334155" }}>Phone number</InputLabel>
              <PhoneInput
                data-test-id="txtMobilePhoneInput"
                specialLabel=""
                country={'ie'}
                value={this.state.kellaPhoneNumber}
                onChange={kellaPhoneNumber => this.setState({ kellaPhoneNumber, isValidPhoneNumberField: false })}
                inputProps={{
                  style: {
                    marginTop: "5px",
                    borderRadius: "8px",
                    width: "100%",
                    border: `1px solid ${this.state.isValidPhoneNumberField || this.state.errorphonenum ? "red" : "#CACACA"}`,
                    boxShadow: this.state.isFocused
                      ? (this.state.isValidPhoneNumberField || this.state.errorphonenum ? "0 0 0 1px red" : "0 0 0 2px #000")
                      : "0 0 0 2px transparent",
                  },
                  onFocus: this.handleFocus,
                  onBlur: this.handleBlur,
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="country" style={{ fontSize: "16px", fontWeight: 700, color: "#334155" }}>Country</InputLabel>
              <TextField
                id="country"
                error={this.state.countryError}
                fullWidth
                placeholder="Select an option"
                variant="outlined"
                data-test-id="countrymobile"
                value={this.state.selectedCountry ? options[this.state.selectedCountry].name : ''}
                onClick={this.toggleMobileDrawer(true)}
                InputProps={{
                  style: { marginTop: "5px", borderRadius: "8px" },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={this.toggleMobileDrawer(true)}>
                        <ArrowDropDownIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Drawer anchor="bottom" open={this.state.mobileDrawerOpen} onClose={this.toggleMobileDrawer(false)}
              PaperProps={{
                style: {
                  borderTopRightRadius: '20px',
                  height: "75%"
                },
              }}
            >
              <Box style={{ display: 'flex', alignItems: "center", justifyContent: "space-between", width: "480px" }}>
                <Box style={{ display: "flex", padding: "10px" }}>
                  <Typography style={{ fontSize: "24px", fontWeight: 600, color: "#14362E" }}>Country</Typography>
                </Box>
                <Box>
                  <IconButton
                    onClick={this.toggleMobileDrawer(false)}
                    style={{ right: '10px' }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
              <Box style={{ display: "flex", padding: "10px" }}>
                <TextField
                  fullWidth
                  placeholder="Search countries"
                  data-test-id="mobile-search"
                  variant="outlined"
                  value={this.state.searchQuery}
                  onChange={this.handleSearch}
                  InputProps={{
                    style: { marginTop: "5px", borderRadius: "8px" },
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      this.state.searchQuery && (
                        <InputAdornment position="end">
                          <IconButton onClick={this.searchQueryfunc}>
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    ),
                  }}
                />
              </Box>
              <Box style={{ overflow: 'auto', marginTop: "30px" }}>
                <List>
                  {Object.keys(options).map((code) => {
                    const countryName = options[code].name.toLowerCase();
                    const searchQuery = this.state.searchQuery.toLowerCase();
                    if (!searchQuery || countryName.includes(searchQuery)) {
                      return (
                        <ListItem
                          button
                          key={code}
                        >
                          <Box
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              width: '100%',
                            }}
                          >
                            <Box>
                              <ListItemText primary={options[code].name} />
                            </Box>
                            <Box>
                              <Radio
                                data-test-id='mobileRadioBtn'
                                checked={this.state.selectedCountry === code}
                                onChange={() => {
                                  this.handleChangeCountry({ target: { value: code } });
                                  this.setState({ searchQuery: options[code].name });
                                }}
                                style={{ color: "green" }}
                              />
                            </Box>
                          </Box>
                        </ListItem>
                      );
                    } else {
                      return null;
                    }
                  })}
                </List>
              </Box>

            </Drawer>

            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="email" style={{ fontSize: "16px", fontWeight: 700, color: "#334155" }}>Email</InputLabel>
              <TextField
                error={this.isEmailError()}
                data-test-id="txtmobileInputEmail"
                placeholder="example@gmail.com"
                fullWidth
                type="text"
                value={this.state.kellaEmail}
                variant="outlined"
                onChange={(e) => this.setEmail(e.target.value.trim())}
                InputProps={{
                  style: {
                    borderRadius: "8px",
                    marginTop: "5px",
                  }
                }}
              />

            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="password" style={{ fontSize: "16px", fontWeight: 700, color: "#334155" }}>Password</InputLabel>
              <TextField
                error={!this.state.errorPasswordFlag ? this.state.isValidPasswordField : (!this.state.isOneNumber && this.state.isValidLength && this.state.isSpecialcharacter)}
                data-test-id="pwdmobileInput"
                placeholder="Your password"
                fullWidth
                type={this.state.enablePassword ? "password" : "text"}
                value={this.state.kellaPassword}
                variant="outlined"
                onChange={(e) => this.setPassword(e.target.value.trim())}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={this.handleClickShowPassword}
                        aria-label="toggle password visibility"
                        edge="end"
                      >
                        {this.state.enablePassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: { marginTop: "5px", borderRadius: "8px" }
                }}
              />
              {this.state.passwordError && <div style={{ color: 'red' }}>{this.state.passwordError}</div>}
            </Grid>
            <Grid item xs={12} style={{ display: "flex", alignItems: "flex-start", gap: "2px" }}>
              {this.passwordRequirementFormate()}
            </Grid>
            <Grid item xs={12} style={{ display: "flex", alignItems: "flex-start", gap: "2px" }}>
              <Box style={{ display: "flex", gap: "2px" }}>
                <FormControlLabel
                  style={{ display: "flex", alignItems: "flex-start", fontFamily: "MyFont" }}
                  control={<Checkbox checked={localStorage.getItem("countSet") === "true"} style={{ borderRadius: "20", color: "#73A58E" }} onClick={this.acceptPrivacyPollicy}
                    data-test-id="handleTermsConditionChecked"
                  />}
                  label={
                    <Typography style={{ fontWeight: 500, fontSize: "14px", paddingTop: "7px" }}>
                      <Box
                        component='span'
                        data-test-id="handleTermsCondsOpen"
                        onClick={(e) => {
                          e.stopPropagation(); this.handleTermsCondsOpen();
                        }} style={{ textDecoration: "none", color: "black", letterSpacing: "1px", }}
                      ><span style={{ fontFamily: "MyFont" }}>By signing up you agree to our </span>
                        <span style={{ fontWeight: 700, color: "#14362E", letterSpacing: "1px" }}>
                          Terms & Condition, GDPR</span>, and our  <span style={{ color: "#14362E", fontWeight: 700, letterSpacing: "1px" }}>Privacy Policy</span>
                      </Box>
                    </Typography>
                  }
                />
              </Box>
              <Dialog id="model_add" maxWidth="sm" fullWidth fullScreen
                open={this.state.showModel} onClose={this.handleDialogClose} >
                <DialogContent>
                  <TermsConditions handleDialogClose={this.handleDialogClose} id={"TermsConditions"} navigation={navigation} />
                </DialogContent>
              </Dialog>
            </Grid>

            <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
              <Box style={{ width: "323px" }}>
                <Button
                  data-test-id="mobile-login-button"
                  variant="contained"
                  fullWidth
                  style={{
                    color: "white",
                    backgroundColor: "#14362E",
                    borderRadius: "8px",
                    fontFamily: "MyFont",
                    letterSpacing: "1px",
                    height: "45px",
                    fontSize: "16px",
                    fontWeight: 700,
                    textTransform: "capitalize"
                  }}
                  onClick={this.handleButtonClick}
                >
                  Next
                </Button>
              </Box>
            </Grid>

            <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
              <Box>
                <Typography onClick={this.alreadyHaveAccount} style={{ fontWeight: 400, fontSize: "16px", fontFamily: "MyFont", letterSpacing: "1px" }}>Already have an account? <Box component='span' style={{ fontSize: "16px", color: "#14362E", textDecoration: "none", fontWeight: 700, marginLeft: "2px", cursor: "pointer" }}>Log in</Box></Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    )
  }
  getQueryParams = () => {
    const params = new URLSearchParams(window.location.search);
    return {
      signup: params.get('signup'),
    };
  };
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <Container maxWidth={false} style={{ display: "flex" }}>
          <Box display={{ xs: "none", sm: "none", md: "flex" }} style={{ width: "100%" }}>
            {(() => {
              const { signup } = this.getQueryParams();
              return this.state.showLastGrid || signup === 'true' ? (
                this.signupWeb()
              ) : (
                this.webBtnView()
              );
            })()}
          </Box>
        </Container>
        <Box display={{ xs: "flex", sm: "flex", md: "none" }}>
          {(() => {
            const { signup } = this.getQueryParams();
            return this.state.showMobileGrid || signup === 'true' ? (
              this.signupMobile()
            ) : (
              <Box
                style={{
                  justifyContent: "center",
                  backgroundImage: `url(${mobileBgLogo})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  width: "100%",
                  height: "100vh",
                }}
              >
                {this.mobileBtnView()}
              </Box>
            );
          })()}
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  CustomBox: {
    width: "559px",
    height: "100vh",
  },
  errorBox: {
    marginTop: "12px",
    display: "flex",
    justifyContent: "flex-start",
    gap: "10px",
    alignItems: "center"
  },
  byDefaultTypo: {
    color: "#334155"
  },
  passwordSuccessTypo: {
    fontSize: "15px",
    fontWeight: 400,
    fontFamily: "MyFont",
    letterSpacing: "0.8"
  },
  passwordSuccessTypoError: {
    fontSize: "15px",
    fontWeight: 400,
    fontFamily: "MyFont",
    letterSpacing: "0.8"
  },
  imgSize: {
    height: "17px"
  },
  CustomXsBox: {
    backgroundImage: `url(${women})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "559px",
    height: "100vh",
  },
  CustomCheckbox: {
    borderRadius: "8px"
  },
  radioUnchecked: {
    color: '#73A58E'
  },
  radioChecked: {
    color: 'white'
  },
  redBorder: {
    borderColor: 'red !important'
  },
  termsWrapper: {
    display: "flex",
    justifyContent: "right",
    padding: "16px 12px",
  },
};

// Customizable Area End