
import React from 'react';
import ResponsiveDrawer from "./ResponsiveDrawer.web"
import { Box,  IconButton, InputAdornment, TextField, Typography, styled, Avatar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';

type TDrawerProps = {
    handleSave: () => void;
    handleClose: () => void;
    open: boolean;
    searchValue: string,
    hadnleChangeSearchValue: (event: any)=> void;
    clearSerchValue: () => void;
    selectedElements: any[]
    handleDeleteSelected: (index: number) => void
    serachData: any[]
    handleCheckItem: (elementvalue:  React.MouseEvent<HTMLInputElement, MouseEvent>, element: any) => void;
    messageValue:string;
    handleChangeMessageValue: (event :any) => void
}
const ShareDrawer = (props: TDrawerProps) => {
    const {
        open,
        handleClose,
        handleSave,
        hadnleChangeSearchValue,
        searchValue,
        clearSerchValue,
        selectedElements,
        handleDeleteSelected,
        serachData,
        handleCheckItem,
        handleChangeMessageValue,
        messageValue,

    } = props;

    const handleBoxClick = (element:any) => {
        const isAlreadySelected = selectedElements.some((v) => v.id === element.id);
        const event: any = {
          target: { checked: !isAlreadySelected },
        };
        handleCheckItem(event, element);
      };
    
    return (
        <ResponsiveDrawer title='Share Post' open={open} handleClose={handleClose} button={{title: 'Send', handleClick: handleSave}}>
            <StyledSearch
                size='small'
                fullWidth
                value={searchValue}
                onChange={hadnleChangeSearchValue}
                variant='outlined'
                data-test-id='share-drawer-search-input'
                InputProps={{
                    startAdornment: <InputAdornment position="start" >
                        <SearchIcon style={{color: '#94A3B8'}}/>
                    </InputAdornment>,
                    endAdornment: searchValue && <InputAdornment position="end" >
                        <IconButton onClick={clearSerchValue} data-test-id='share-drawer-clear-icon'>
                            <CloseIcon style={{color: '#94A3B8'}}/>
                        </IconButton>
                    </InputAdornment>,
                }}
            />
            <Box style={{ display: "flex", flexWrap: 'wrap', marginBottom:'15px' }}>
                {
                    selectedElements.map((element: any, index: any) => (
                        <Box className="selectedMemberChip" key={index} style={{ margin: '0px 10px 10px 10px', padding: '6px 8px 6px 12px', alignItems: 'center', height: '32px', width: 'auto', borderRadius: '26px', background: '#F1F5F9', justifyContent: 'center', display: 'flex' }}>
                        <Typography style={{ color: '#475569', lineHeight: "22px", marginRight: '22px', fontSize: '14px', fontFamily: "MyFont", }}>{element.value}</Typography>
                        <span onClick={() => handleDeleteSelected(index)} style={{ padding: "0px 5px" }}>
                          <i className="fa-solid fa-xmark" style={{ fontSize: '15px', cursor:'pointer' }}></i>
                        </span>
                      </Box>
                    ))
                }
            </Box>
            <Box>
                {
                  serachData.map((element: any, index: any) => {
                    return (
                        <Box className="box5" key={index} 
                            style={{
                                boxShadow: "rgba(0, 0, 0, 0.24) 0px 1px 0px",
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "5px",
                                alignItems: "center",
                                height: "48px",
                                marginTop: "11px",
                                cursor: "pointer"
                            }}
                            onClick={()=>handleBoxClick(element)}
                        >
                <Box className="box6" style={{
                        display: "flex",
                        borderRadius: "9px",
                        alignItems: "center",
                }}>
                  <Avatar src={element.profile_picture?.url} style={{ height: '44px', width: '44px' }} />
                  <Box style={{ display: 'flex', flexDirection: 'column', marginLeft: "18px", }}><span style={{ fontSize: '16px', lineHeight: '24px', fontWeight: 700, color: '#0F172A', fontFamily: 'MyFont', }}>{element.name}</span><span style={{ fontWeight: 500, fontFamily: 'MyFont', color: '#64748B', lineHeight: '18px' }}>{element.rol}</span></Box>
                </Box>
                    <span className="inputSelectedMember"><input type="checkbox" 
                    checked={selectedElements.some((v: any) => v.id === element?.id)} 
                    onClick={(elementvalue) => handleCheckItem(elementvalue, element)} /></span>
              </Box>
                    )
                  })
                }
            </Box>
            <Box sx={{marginTop: '12px'}}>
                <TextField  data-test-id='share-drawer-message-input' value={messageValue} onChange={handleChangeMessageValue} placeholder="Write Message" variant='outlined' size='small' fullWidth style={{fontFamily:'MyFont',}}/>
            </Box>

        </ResponsiveDrawer>
    )
}

const StyledSearch = styled(TextField)({
    fontFamily: 'MyFont',
    fontSize: '16px',
    fontWeight: 700,
    marginBottom:'16px'

})

export default ShareDrawer;