import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React, { ChangeEvent } from "react";
import { ScrollView } from "react-native";
import {
  InputProps
} from "@material-ui/core";
import toast from 'react-hot-toast';
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface IChatData {
  id: string;
  attributes: {
    id: number;
    name: string;
    is_notification_mute: boolean;
    accounts_chats: [
      {
        id: string;
        attributes: {
          account_id: number;
          muted: boolean;
          unread_count: number;
        };
      }
    ];
    messages: IMessage[];
  };
  relationships: { accounts: { data: { id: string; type: string }[] } };
}

export interface IMessage {
  id: string;
  type: "chat_message";
  attributes: {
    id: number;
    message: string;
    account_id: number;
    chat_id: number;
    created_at: string;
    updated_at: string;
    is_mark_read: boolean;
    attachments: { id: number, url: string }[] | null;
  };
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  chatId: number;
  message: string;
  groupmessage: string;
  localmessage: string;
  accountIdInput: string;
  accountId: number;
  chatData: any;
  groupChatData: any;
  groupInfo: any;
  currentUserInfo: any;
  chattabs: any;
  currentUserID: any;
  isVisibleModal: boolean;
  isVisiblePreviewModal: boolean;
  imageUrl: string;
  docRes: unknown;
  keyboardHeight: number;
  muted: boolean | null;
  open: boolean;
  openmember: boolean,
  MemberListData: any,
  selectedmember: any,
  modalopen: boolean,
  reportopen: boolean,
  roomname: string,
  groupdescription: string,
  groupMember: any,
  updatedgroupname: any,
  updateddescription: any,
  finalImg: any,
  selectedImage: any,
  reportingListing: any,
  setBlockreson: string,
  setBlockKey: string,
  uploadedImages: { base64convert: string; store: File }[],
  newAddedImg: { base64convert: string; store: File }[],
  newAddedImgForGroup: { base64convert: string; storefile: File }[];
  uploadedImagesForGroup: { base64convert: string; storefile: File }[];
  editdrawer: boolean,
  addmembervalue:string
  chatMenu: null | Element
  blockDrawer: boolean;
  removeDrawer: boolean;
  continueBlock: boolean;
  reasonTitle: string;
  reasonDescription: string;
  pickerAnchor: null | SVGSVGElement
  pickerGroupAnchor: null | SVGSVGElement

  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  EmojiClickEvent: {
    emoji: string;
  }
  blockUserApiCallID: string;
  removeFromMyCircleApiCallID: string
  // Customizable Area End
}

export default class ViewChatController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getChatApiCallId: string = "";
  addUserToChatApiCallId: string = "";
  getGroupInfoApiCallId: string = "";
  sendMessageApiCallId: string = "";
  toggleMuteApiCallId: string = "";
  getmemberListApiCallId: string = "";
  removememberCallId: string = "";
  reportPointCallId: string = "";
  AddmemberCallId: string = "";
  reportuserID: string = "";
  updategroupDataId: string = "";
  updateReadMessageApiCallId: string = "";
  refreshChatInterval: unknown;
  getPostDataId:string = "";
  blockUserApiCallID = ''
  removeFromMyCircleApiCallID = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      chatId: 3,
      message: "",
      groupmessage: "",
      localmessage: "",
      accountId: -1,
      accountIdInput: "",
      chatData: [],
      groupChatData: [],
      groupInfo: "",
      currentUserInfo: "",
      currentUserID: "",
      chattabs: "",
      isVisibleModal: false,
      isVisiblePreviewModal: false,
      imageUrl: "",
      docRes: null,
      keyboardHeight: 0,
      muted: null,
      open: false,
      openmember: false,
      MemberListData: [],
      selectedmember: [],
      modalopen: false,
      reportopen: false,
      roomname: "",
      groupdescription: "",
      groupMember: [],
      updatedgroupname: "",
      updateddescription: "",
      finalImg: "",
      selectedImage: "",
      reportingListing: [],
      setBlockreson: "",
      setBlockKey: "",
      uploadedImages: [],
      newAddedImg: [],
      newAddedImgForGroup: [],
      uploadedImagesForGroup: [],
      editdrawer: false,
      addmembervalue:"",
      chatMenu: null,
      blockDrawer: false,
      removeDrawer: false,
      continueBlock: false,
      reasonTitle: '',
      reasonDescription: '',
      pickerAnchor: null,
      pickerGroupAnchor: null
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {

    super.componentDidMount();
    this.getToken();

    const SenderToken = localStorage.getItem("accessToken");
    const room_id = localStorage.getItem("room_id");
    const tabs = localStorage.getItem("tabs");
    const Senderid = localStorage.getItem("account_id")

    this.setState({ chattabs: tabs })
    this.setState({ currentUserID: Senderid })
    this.getOneToOneChatDetails(SenderToken, room_id);



    this.refreshChatInterval = setInterval(
      () => this.getOneToOneChatDetails(SenderToken, room_id), 5000
    )
  }



  async componentWillUnmount() {
    clearInterval(this.refreshChatInterval as number);
  }
  changeChatMenuState = (element: Element | null) => {
    this.setState({chatMenu: element})
  }


  handleChangeBlockDrawer = (value: boolean) => {
    this.setState({blockDrawer: value});
    
  };
  handleChangeRemoveDrawer = (value: boolean) => {
    this.setState({removeDrawer: value})
    this.changeChatMenuState(null)
  }
  handleChangeContinueBlock = (value: boolean) => {
    this.setState({continueBlock: value})
  }
  changePickerAnchor = (pickerAnchor: null | SVGSVGElement) => {
    this.setState({pickerAnchor})
  }
  changeGroupPickerAnchor = (pickerGroupAnchor: null | SVGSVGElement) => {
    this.setState({pickerGroupAnchor})
  }
  handleRemoveUser = () => {
    const header = {
      "Content-Type": 'application/json',
      token: localStorage.getItem("accessToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.removeFromMyCircleApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.removeFromMyCircleEndPoint}${this.state.currentUserInfo.receiver.id}/request_remove`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'DELETE'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  };
  handleBlockUser = () => {
    const header = {
      "Content-Type": 'application/json',
      token: localStorage.getItem("accessToken"),
    };

    const httpBody = {
      blocker_id: this.state.currentUserInfo.receiver.id,
      reason_title: this.state.reasonTitle,
      reason_description: this.state.reasonDescription,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.blockUserApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.blockUserEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleReasonSelect = (title: string, description: string) => {
    this.setState({ reasonTitle: title, reasonDescription: description });
  }
  handleBlockedUserResponse = (responseJson: any) => {
    if (responseJson) {
      this.handleChangeContinueBlock(false);
    }
  };
  handleRemoveUserResponse = (responseJson: any) => {
    if (responseJson) {
      this.handleChangeRemoveDrawer(false);
    }
  };
  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  searchvalue = (event: any) => {
    this.setState({addmembervalue:event.target.value})
    this.getmemberList(event.target.value)
  }

  removevalue=()=>{
    this.setState({addmembervalue:""})
    this.setState({MemberListData:[]})
  }

  Emojiclick = (event: SS["EmojiClickEvent"]) => {
    this.setState({ message: this.state.message + event.emoji })
    this.setState({ pickerAnchor: null })

  }

  Emojiclickgroup = (event: SS["EmojiClickEvent"]) => {
    this.setState({ groupmessage: this.state.groupmessage + event.emoji })
    this.setState({ pickerGroupAnchor: null })
  }

  openEmoji = (event: any) => {
    this.setState({ pickerAnchor: event.target })
  }

  openGroupEmoji = (event: any) => {
    this.setState({ pickerGroupAnchor: event.target })
  }

  goBack = () => {
    window.location.href = "Chat"
  }
  getmemberList = async (searchvalue: any) => {
    const token = localStorage.getItem("accessToken")
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getmemberListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_addfriends/connections?search=${searchvalue}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };



  handleInfodrawerOpen = () => {
    const room_id = localStorage.getItem("room_id");

    this.getGroupInfo(room_id)
    this.setState({ open: true })
  }

  getGroupInfo = (roomid: string | null) => {

    const token = localStorage.getItem("accessToken")
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getGroupInfoApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_chat/groups/group_info?id=${roomid}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }




  leavegroup = () => {
    this.setState({ modalopen: true })
  }

  handleClose = () => {
    this.setState({ modalopen: false })
    const userid = localStorage.getItem("account_id")
    this.removeMembercall(userid)
  };

  reportSidebaaropen = () => {
    this.setState({ reportopen: true })
    this.reportpointApicall()
  }

  setblockcolor = (element: any, index: any) => {
    this.setState({ setBlockreson: element.id, setBlockKey: index })
  }

  reportuser = () => {
    const token = localStorage.getItem("accessToken")
    const roomid = localStorage.getItem("room_id")


    const final = {
      data: {
        attributes: {
          "room_id": roomid,
          "reporting_point_id": this.state.setBlockreson
        }
      }
    }


    const reportuser = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.reportuserID = reportuser.messageId;

    reportuser.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_help_centre/report_groups`
    );

    reportuser.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: `${token}`,
      })
    );


    reportuser.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(final)
    );


    reportuser.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `POST`
    );


    runEngine.sendMessage(reportuser.id, reportuser);
  }

  handleAddmemberDrawer = () => {
    this.setState({ openmember: true })
  }

  toggleDrawermember = (data: boolean) => {
    this.setState({ openmember: data })
  }

  toggleDrawermemberreport = (data: boolean) => {
    this.setState({ reportopen: data })
  }

  drawermemberclose = () => {
    this.setState({ openmember: false })
  }

  toggleDrawer = (data: boolean) => {
    this.setState({ open: data })
  }

  toggleDrawerClose = ()=>{
    this.setState({ open: false })
  }

  drawerClose = () => {
    this.setState({ editdrawer: false })
  }

  getvaluemembercheck = (elementdata: any, data2: any) => {

    if (elementdata.target.checked) {
      const IteamIndex2 = this.state.selectedmember.findIndex((iteam: any) => iteam.id === data2.id);


      if (IteamIndex2 < 0) {
        const storetype = [...this.state.selectedmember, { id: data2.id, value: data2.first_name + data2.last_name }]
        this.setState({ selectedmember: storetype });
      }
    } else {
      const deleteSelectedType = this.state.selectedmember.filter((element: any) => {
        return element.id !== data2.id
      })

      this.setState({ selectedmember: deleteSelectedType });
    }
  }

  handleselectmemberDelete = (elementdata: number) => {
    const deleteSelectedType = this.state.selectedmember.filter((element: any, index: number) => {
      return index !== elementdata
    })

    this.setState({ selectedmember: deleteSelectedType });
  }




  reportSidebaarClose = () => {
    this.setState({ reportopen: false })
  }


  handleMessageChange = (e: any) => {
    this.setState({ message: e.target.value.trimStart() });
  };

  addmemberapi = () => {
    this.addmemberapicall()
  }

  editopen = () => {
    this.setState({ editdrawer: true })
    const room_id = localStorage.getItem("room_id");

    this.getGroupInfo(room_id)
  }

  addmemberapicall = () => {
    const token = localStorage.getItem("accessToken");
    const room_id = localStorage.getItem("room_id")

    const memberid = this.state.selectedmember.map((element: any) => element.id);
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };


    const attrs = {
      "room_id": room_id,
      "id_list": memberid
    };

    const bodydata = {
      attributes: attrs,
    };

    const finaldata = {
      data: bodydata
    }


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.AddmemberCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_chat/groups/add_member`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(finaldata)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `POST`
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  reportpointApicall = () => {
    const token = localStorage.getItem("accessToken");

    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.reportPointCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_help_centre/reporting_points`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    ;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `GET`
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  removemember = (userid: any) => {
    this.removeMembercall(userid)
  }

  removeMembercall = (userid: any) => {
    const token = localStorage.getItem("accessToken");
    const room_id = localStorage.getItem("room_id")
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.removememberCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_chat/groups/remove_member?room_id=${room_id}&participant_id=${userid}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `DELETE`
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }



  handleFileChange = (event: any) => {
    const files = event.target.files;
    if (!files) return;

    if (files.length > 1) {
      const base64: { base64convert: string; store: File }[] = [];
      for (const file of files) {
        if (file.type.startsWith("image/")) {
          base64.push({ base64convert: URL.createObjectURL(file), store: file });
        }
      }

      this.setState({
        uploadedImages: base64,
        newAddedImg: base64,
      });
    } else {
      const file = files[0];
      if (file.type.startsWith("image/")) {
        const base64 = URL.createObjectURL(file);
        this.setState({
          uploadedImages: [{ base64convert: base64, store: file }],
          newAddedImg: [{ base64convert: base64, store: file }],
        });
      }
    }
  };

  handleFileChangegroup = (event: any) => {
    const files = event.target.files;
    if (!files) return;

    const base64data: { base64convert: string; storefile: File }[] = [];

    for (const file of files) {
      if (file.type.startsWith("image/")) {
        base64data.push({
          base64convert: URL.createObjectURL(file),
          storefile: file,
        });
      }
    }

    if (base64data.length > 0) {
      this.setState({
        uploadedImagesForGroup: base64data,
        newAddedImgForGroup: base64data,
      });
    }
  }

  handleSendMessage = () => {

    const Senderid = localStorage.getItem("account_id");
    const currentuser = Array.isArray(this.state.chatData) ? this.state.chatData?.filter((element: any, index: any) => {
      return element.attributes.sender.id == Senderid
    }) : [];


    const localmsgobj = {
      id: Senderid,
      type: "message",
      attributes: {
        sender: currentuser.length > 0 && currentuser[0].attributes.sender,
        id: 56,
        content: this.state.message,
        read: true,
        created_at: "2024-05-29T09:29:03.078Z",
        updated_at: "2024-05-29T09:29:03.078Z",
        "share":{},
        media_url: {
          url: this.state.uploadedImages[0]?.base64convert
        }
      }
    }
    const addeddata = this.state.chatData.length > 0 && [...this.state.chatData, localmsgobj]

    const roomid = localStorage.getItem("room_id");
    this.state.chatData.length == 1 && this.setState({ chatData: addeddata });


    this.sendChatMessage(this.state.message, roomid)
    this.setState({ message: "" })
    this.setState({ uploadedImages: [] })
  };

  handleMessageChangegroup = (event: any) => {
    this.setState({ groupmessage: event.target.value.trimStart() });
  };

  handleSendMessageforgroup = () => {
    const Senderid = localStorage.getItem("account_id");

    const currentuser = this.state.groupChatData?.filter((element: any, index: any) => {
      return element.attributes?.sender.id == Senderid;
    });


    const localmsgobj = {
      id: Senderid,
      type: "message",
      attributes: {
        "sender": currentuser.length > 0 && currentuser[0].attributes.sender,
        "id": 56,
        "content": this.state.groupmessage,
        "read": true,
        "created_at": "2024-05-29T09:29:03.078Z",
        "updated_at": "2024-05-29T09:29:03.078Z",
        "share":"",
        media_url: {
          url: this.state.uploadedImagesForGroup[0]?.base64convert
        }
      }
    }
    const addeddata = this.state.groupChatData.length > 0 && [...this.state.groupChatData, localmsgobj]
    const roomid = localStorage.getItem("room_id");
    
    this.state.groupChatData.length == 1 && this.setState({ groupChatData: addeddata });
    this.sendChatMessage(this.state.groupmessage, roomid)
    this.setState({ groupmessage: "" })
    this.setState({ uploadedImagesForGroup: [] })
  }

  getOneToOneChatDetails(token: any, chatId: any) {


    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getChatApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_chat/messages/room_messages?room_id=${chatId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  sendChatMessage = async (message: any, roomid: any) => {
   
    const token = localStorage.getItem("accessToken");

    const header = {
      token: token,
    };

    const formData = new FormData();
    formData.append("message[content]", message);
    formData.append("room_id", roomid)
    if (this.state.newAddedImg) {
      for (const image of this.state.newAddedImg) {
        formData.append("message[attachment]", image.store);
      }
    }

    if (this.state.newAddedImgForGroup) {
      for (const image of this.state.newAddedImgForGroup) {
        formData.append("message[attachment]", image.storefile);
      }
    }


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendMessageApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_chat/messages`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleUpdategroup = () => {
    const token = localStorage.getItem("accessToken");
    const room_id = localStorage.getItem("room_id");

    const header = {
      token: token,
    };

    const formData = new FormData();
    formData.append("data[attributes[room_name]]", this.state.updatedgroupname);
    formData.append("data[attributes[description]]", this.state.updateddescription)

    if (this.state.finalImg) {
      formData.append("data[attributes[group_profile_image]]", this.state.finalImg);
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updategroupDataId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_chat/groups/${room_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateReadMessages = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const bodyData = {
      chat_id: this.state.chatId,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateReadMessageApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateReadMessageApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));


    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      this.handleSessionResponse(message);
    } else if (this.isRestApiResponse(message, responseJson)) {
      this.handleRestApiResponse(apiRequestCallId, responseJson);
    }
  }

  handleSessionResponse(message: Message) {
    const token: string = message.getData(getName(MessageEnum.SessionResponseToken));
    runEngine.debugLog("TOKEN", token);
  }



  isRestApiResponse(message: Message, responseJson: any): boolean {
    return responseJson && getName(MessageEnum.RestAPIResponceMessage) === message.id;
  }

  handleRestApiResponse(apiRequestCallId: string, responseJson: any) {
    switch (apiRequestCallId) {

      case this.getChatApiCallId:
        this.handleSignelChatresponse(responseJson)
        break;
      case this.sendMessageApiCallId:
        this.handlesendMessageresponse();
        break;
      case this.getmemberListApiCallId:
        this.getmemberlistresponse(responseJson);
        break;
      case this.getGroupInfoApiCallId:
        this.getGroupInfoResponse(responseJson);
        break;
      case this.removememberCallId:
        this.removememberresponse(responseJson);
        break;
      case this.AddmemberCallId:
        this.addmemberresponse(responseJson);
        break;
      case this.reportPointCallId:
        this.reportpointresponse(responseJson);
        break;
      case this.reportuserID:
        this.reportuserresponse(responseJson);
        break;
      case this.updategroupDataId:
        this.updateGroupresponse(responseJson);
        break;
      case this.getPostDataId:
        this.postshareresponse(responseJson);
        break;
      case this.blockUserApiCallID:
        this.handleBlockedUserResponse(responseJson);
        break;
      case this.removeFromMyCircleApiCallID:
          this.handleRemoveUserResponse(responseJson)
          break;
      default:
        break;
    }
  }

  handleSignelChatresponse(responseJson: any) {
    if (responseJson.meta.group_info) {
      this.setState({
        groupChatData: responseJson.data,
        groupInfo: responseJson.meta
      })

    } else {
      this.setState({
        chatData: responseJson.data,
        currentUserInfo: responseJson.meta,
      });
    }

  }

  handleRedirectHome=(postid:number)=>{
    const token = localStorage.getItem("accessToken")

    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPostDataId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_posts/find_record?items=20&id=${postid}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handlesendMessageresponse() {
    this.handleSendMessageResponse();

  }

  getmemberlistresponse(responseJson: any) {
    if (responseJson.accounts) {
      this.setState({ MemberListData: responseJson.accounts })
    }
  }

  getGroupInfoResponse(responseJson: any) {
    this.setState({ roomname: responseJson.room_name })
    this.setState({ groupdescription: responseJson.description })
    this.setState({ groupMember: responseJson.users })

    this.setState({ updatedgroupname: responseJson.room_name })
    this.setState({ updateddescription: responseJson.description })
  }

  handleinputchange = (event: any) => {
    const { value } = event.target;

    this.setState({ updatedgroupname: value })
  }

  handledescriptionchange = (event: any) => {
    const { value } = event.target;

    this.setState({ updateddescription: value })
  }

  handleImgupdate = (event: any) => {
    const file = event.target.files && event.target.files[0];
    if (file) {

      this.setState({ finalImg: file })

      this.setState({ selectedImage: URL.createObjectURL(file) });

    }
  }

  removememberresponse(responseJson: any) {
    if (responseJson.data.message !== "") {
      toast.success(responseJson.data.message)


      const room_id = localStorage.getItem("room_id");
      this.getGroupInfo(room_id)
      this.setState({ open: false })
    } else {
      toast.error("invalid details")
      this.setState({ open: false })
    }
  }

  addmemberresponse(responseJson: any) {
    if (responseJson.message !== "") {
      toast.success(responseJson.message)
      this.setState({ selectedmember: [] })
      this.setState({ open: false })
      this.setState({ openmember: false })

      const room_id = localStorage.getItem("room_id");
      this.getGroupInfo(room_id)
    } else {
      toast.error("user is already in group")
      this.setState({ selectedmember: [] })
      this.setState({ open: false })
    }
  }

  reportpointresponse(responseJson: any) {
    this.setState({ reportingListing: responseJson.data })
  }

  reportuserresponse(responseJson: any) {
    if (responseJson.data) {
      toast.success("sucess fully reported !")
      this.setState({ reportopen: false })
    }
  }

  updateGroupresponse(responseJson: any) {
    if (responseJson.data) {
      this.setState({ editdrawer: false })
      toast.success("data sucessfully updated");
    }
  }


  postshareresponse(responseJson:any){
    if(responseJson.page_number){
      const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), `NewsFeed`);
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg)

      localStorage.setItem("indexpage",responseJson.index_in_page);
      localStorage.setItem("pagenumber",responseJson.page_number)
    }
  }

  handleSendMessageResponse() {
    const tabs = localStorage.getItem("tabs");
    const Senderid = localStorage.getItem("account_id");
    const SenderToken = localStorage.getItem("accessToken");
    const room_id = localStorage.getItem("room_id");
    
        this.setState({ uploadedImages: []})
        this.setState({uploadedImagesForGroup:[]})
        this.setState({newAddedImgForGroup:[]})
        this.setState({newAddedImg:[]})

    this.setState({ chattabs: tabs, currentUserID: Senderid });
    this.getOneToOneChatDetails(SenderToken, room_id);
  }


  // Customizable Area End
}
