import { Box, Drawer, Typography, styled, Button, useMediaQuery, useTheme } from "@material-ui/core"
import React from "react";
import CloseIcon from "@material-ui/icons/Close";

interface TProps {
    title: string;
    handleClose(): void;
    open: boolean;
    children: React.ReactNode,
    button?: {
      title: string
      handleClick: () => void;
      disabled?: boolean
    }
}

const ResponsiveDrawer = ({title, handleClose, open, children, button}: TProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return <CustomDrawer open={open} onClose={handleClose} anchor={isMobile ? "bottom" : "right"} className="overrideDrawer">
        <Box style={{borderBottom: "1.04px solid #E2E8F0", padding: '0 43px 20px 28px',  flexShrink: 0,}}>
              <Box style={{padding: "12px 0px 0px 0px",display: "flex", justifyContent:'space-between', alignItems:'center'}}>
                  <Typography style={{ fontSize:"24px",fontWeight:700, color: '#14362E', }}>{title}</Typography>
                <Box>
                  <CloseIcon onClick={handleClose} fontSize="small" style={{cursor: 'pointer', scale: '0.8'}}/>
                </Box>
              </Box>
        </Box>
        <Box sx={{padding:'20px 28px', height: '100%', flexGrow: 1,}} style={{overflowY:'auto'}}>
          {children}
        </Box>
        
        {button && (
          <Box sx={{flexGrow: 0, padding: '20px 28px'}}>
            <StyledButton disabled={button.disabled} onClick={button.handleClick} variant="contained" fullWidth>{button.title}</StyledButton>
          </Box>

        )}
        
    </CustomDrawer>
}

const StyledButton = styled(Button)({
  backgroundColor: "#14362E",
  color:"#FFFFFF",
  textTransform: 'unset' as const,
  fontWeight: 700,
  fontFamily: 'MyFont',
  letterSpacing: '0.08em',
  "& .MuiButton-label": {
    fontSize:'16px',
  },
  "&:hover": {
    backgroundColor: "#14362E",
  }

});
const CustomDrawer = styled(Drawer)({
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: -1, 
  },
    '& .MuiDrawer-paper': {
        borderTopLeftRadius: '24px',
        width:'537px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100vh',
        boxShadow: '0px 8.31px 33.24px 0px #0000000F'
        
      }, 
    '@media (max-width: 600px)': {
      '& .MuiDrawer-paper': {
        bottom: '0',
        top: 'unset',
        height: '80vh',
        paddingBottom: '24px',
        width:'100%'
      },
  },
})

export default ResponsiveDrawer