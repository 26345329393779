import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  Button,
  Drawer,
  Grid,
  TextField,
  InputLabel,
  IconButton,
  MenuItem,
  Modal,
  Container,
  Hidden,
} from "@material-ui/core";
const configJSON = require("./config");
import { contactus, image_chevron, successCheck, failedCheck } from "./assets";
import CloseIcon from "@material-ui/icons/Close";
import { ArrowBack } from "@material-ui/icons";
import PhoneInput from "react-phone-input-2";
// Customizable Area End

import ContactUsController, { Props } from "./ContactUsController.web";

class ContactUs extends ContactUsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  formSubmittedSuccessfully = () => {
    return(
        <Modal
        open={this.state.successModalOpen}
        onClose={this.handleCloseSuccessModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box sx={{ ...webStyle.modalStyle, borderRadius: "0 10px 0 0" }}>
        <img src={successCheck} alt="Img" style={webStyle.modalImg}/>
        <Box sx={{...webStyle.modalStyleTypo}}>
            Form Submitted
        </Box>
        <Box sx={{...webStyle.modalStyleTypoSecond}}>
        Your form has been submitted and one of our team will be in contact with you as soon as possible.
        </Box>
        <Button
            fullWidth
            variant="contained"
            data-test-id="submitButton"
            style={{
            ...webStyle.contactDrawerSubmitBtn,
            position: "relative",
            textTransform: "capitalize",
            backgroundColor: "#14362E"
            }}
            onClick={this.handleCloseSuccessModal}
        >
            Proceed
        </Button>
        </Box>
    </Modal>
    );
  }

  formSubmissionFailed = () => {
    return(
        <Modal
        open={this.state.failedModalOpen}
        onClose={this.handleCloseFailedModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box sx={{ ...webStyle.modalStyle, borderRadius: "0 20px 0 0" }}>
        <img src={failedCheck} alt="Img" style={webStyle.modalImg}/>
        <Box sx={{...webStyle.modalStyleTypo}}>
            Form Submission Failed
        </Box>
        <Box sx={{...webStyle.modalStyleTypoSecond}}>
        Your form has not been submitted. Please fill the contact us form again for submission.
        </Box>
        <Button
            fullWidth
            variant="contained"
            data-test-id="submitButton"
            style={{
            ...webStyle.contactDrawerSubmitBtn,
            position: "relative",
            textTransform: "capitalize",
            backgroundColor: "#14362E"
            }}
            onClick={this.handleRetryBtn}
        >
            Retry
        </Button>
        </Box>
    </Modal>
    );
  }

  // For Desktop view
  contactUsDrawer = () => {
    return (
      <Drawer
        anchor="right"
        open={this.state.contactUsDrawerOpen}
        onClose={this.toggleContactUsDrawer(false)}
        PaperProps={{
          style: {           
            width: "40%",
            borderTopRightRadius: "20px",
          },
        }}
      >
        <Box
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",            
            borderBottom: "1px solid #E2E8F0",
          }}
        >
          <Box style={{ 
            display: "flex", 
            padding: "15px" 
            }}
            >
            <Typography
              style={{ color: "#14362E",
                fontSize: "24px",
                fontWeight: "bolder",
                marginBottom: "5px",
                fontFamily: "MyFont",
                letterSpacing: "1.4",                
              }}
            >
              Contact Us
            </Typography>
          </Box>
          <Box>
            <IconButton
              style={{ right: "10px" }}
              onClick={this.toggleContactUsDrawer(false)}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>

        <Box style={{ 
            padding: "20px" 
            }}
        >
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12}>
              <Typography style={webStyle.contactUsDrawerTxt}>
                Please fill the form below and we will get back to you as soon
                as possible.
              </Typography>
              <InputLabel htmlFor="firstName"
                style={webStyle.contactUsDrawerInputLabel}
              >
                First Name
              </InputLabel>
              <TextField  variant="outlined"
                fullWidth
                data-test-id="firstName"              
                type="text"
                value={this.state.firstName}
                placeholder="Enter First Name"
                onChange={(e) => {
                const value = e.target.value;
                const onlyLetters = value.replace(/[^A-Za-z]/g, '');
                this.handleFirstName(onlyLetters.trim())}} 
                error={!!this.state.errors.firstName}
                helperText={this.state.errors.firstName ? 'First Name is required' : ''}
                InputProps={{
                  style: {
                    borderColor: "#CBD5E1",
                    marginTop: "5px",
                    borderRadius: "8px",                  
                  },
                }}
             />
            </Grid>
            <Grid item xs={12}>
              <InputLabel
                htmlFor="lastName"
                style={webStyle.contactUsDrawerInputLabel}
              >
                Last Name
              </InputLabel>
              <TextField fullWidth            
                type="text"
                data-test-id="lastName"
                variant="outlined"
                value={this.state.lastName}
                placeholder="Enter Last Name"
                error={!!this.state.errors.lastName}
                helperText={this.state.errors.lastName ? 'Last Name is required' : ''}
                onChange={(e) => {
                  const value = e.target.value;
                  const onlyLetters = value.replace(/[^A-Za-z]/g, '');
                  this.handleLastName(onlyLetters.trim())}} 
                InputProps={{
                  style: { marginTop: "5px",
                    borderRadius: "8px",
                    borderColor: "#CBD5E1",
                  },
                }}
            />
            </Grid>

            <Grid item xs={12}>
              <InputLabel
                htmlFor="phoneNumber"
                style={webStyle.contactUsDrawerInputLabel}
              >
                Phone number
              </InputLabel>
              <PhoneInput
                data-test-id="txtPhoneInput"
                specialLabel=""
                country={"ie"}
                value={this.state.phoneNumber}
                onChange={(value, country) => {
                    if (country && 'dialCode' in country) {
                      this.setState({
                        phoneNumber: value,
                        countryCode: country.dialCode
                      });
                    } 
                  }}
                inputProps={{
                  style: {
                    marginTop: "5px",
                    borderRadius: "8px",
                    width: "100%",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <InputLabel  htmlFor="subject"            
                style={webStyle.contactUsDrawerInputLabel}
              >
                Subject
              </InputLabel>

              <TextField fullWidth
                select
                data-test-id="subject"
                variant="outlined"
                placeholder="Select an option"
                value={this.state.subject}
                onChange={this.handleSubjectChange}            
                error={!!this.state.errors.subject}
                InputProps={{
                  style: { borderRadius: "8px",               
                    marginTop: "5px",
                  } }}
                helperText={this.state.errors.subject ? 'Subject is required' : ''}
                SelectProps={{
                  displayEmpty: true,
                  MenuProps: {
                    PaperProps: {
                      style: {
                        transform: 'translateY(56%)', 
                        maxHeight: 200,
                        overflowY: 'auto',
                      },
                    },
                  },
                }}
              >
                <MenuItem disabled value="">
                  <span 
                    style={{ fontFamily: "'MyFont', 'Halyard Display'", fontSize: "16px",color: "#94A3B8",  fontWeight: 700, letterSpacing: "0.05em" }}>
                    Select an option
                  </span>
                </MenuItem>
                {this.state.subjects.map((option, index) => (
                  <MenuItem key={index} value={option}>{option}
                    
                  </MenuItem>
                ))}
              </TextField>

              <TextField fullWidth
                multiline
                data-test-id="txtInputDescription"
                placeholder="Describe your Inquiry..."  
                value={this.state.description}           
                rows={4}
                onChange={this.handleDescriptionChange}
                variant="outlined"
                InputProps={{ style: { borderRadius: "8px", marginTop: "10px", } }}
                helperText={this.state.errors.description ? 'Description is required' : ''}
                error={!!this.state.errors.description}
              />
            </Grid>
            
          </Grid>
        </Box>
        <Box
          mr={2}
          ml={2}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
            {this.formSubmittedSuccessfully()}
            {this.formSubmissionFailed()}
          <Button
            fullWidth
            variant="contained"
            data-test-id="submitButton"
            style={{
              ...webStyle.contactDrawerSubmitBtn,
              position: "relative",
              bottom:"15px",
              textTransform: "capitalize",
              backgroundColor: this.state.formValid ? "#14362E" : "#E2E8F0",
            }}
            onClick={this.handleSubmitButton}
          >
            Submit
          </Button>
            </Box>
      </Drawer>
    );
  };

  // For Mobile view
  //istanbul ignore next
  contactUsDrawerMobile = () => {
    return (
      <Drawer
        anchor="right"
        open={this.state.contactUsDrawerOpen}
        onClose={this.toggleContactUsDrawer(false)}
        PaperProps={{ style: { width: "100%" } }}
        >
        <Box
          style={{ display: "flex",
            alignItems: "center",
            justifyContent: "space-between", 
            width: "100%", 
            marginTop:"20px",          
            borderBottom: "1px solid #E2E8F0",          
          }} >
          <Box>
            <IconButton style={{ left: 0 }}
              onClick={this.toggleContactUsDrawer(false)}>
                  <ArrowBack />
              </IconButton>
          </Box>
          <Box>
            <IconButton
              style={{ right: "10px" }}
              onClick={this.toggleContactUsDrawer(false)}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
          <Box style={{marginTop:"10px", marginLeft:"20px"}}>
            <Typography
                style={{ marginBottom: "5px",
                  fontFamily: "MyFont",
                  letterSpacing: "0.05em",
                  color: "#14362E",
                  fontSize: "20px",
                  fontWeight: "bolder",                                 
                }} >
                Contact Us
              </Typography>
          </Box>
        <Box style={{ padding: "20px" }}>
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12}>
              <Typography style={webStyle.contactUsDrawerTxt}>
                Please fill the form below and we will get back to you as soon as possible.
              </Typography>
              <InputLabel 
                htmlFor="firstName"
                style={webStyle.contactUsDrawerInputLabel} >
                First Name
              </InputLabel>
              <TextField  variant="outlined" fullWidth
                type="text"
                data-test-id="firstName"                              
                value={this.state.firstName}
                placeholder="Enter First Name"
                error={!!this.state.errors.firstName}
                helperText={this.state.errors.firstName ? 'First Name is required' : ''}
                onChange={(e) => {
                  const value = e.target.value;
                  const onlyLetters = value.replace(/[^A-Za-z]/g, '');
                  this.handleFirstName(onlyLetters.trim())}}              
                InputProps={{ style: { marginTop: "5px",
                    borderColor: "#CBD5E1",               
                    borderRadius: "8px" } }}
                />
            </Grid>
            <Grid item xs={12}>
              <InputLabel htmlFor="lastName"             
                style={webStyle.contactUsDrawerInputLabel}
              >
                Last Name
              </InputLabel>
              <TextField fullWidth  type="text"                       
                data-test-id="lastName"
                variant="outlined"
                value={this.state.lastName}
                placeholder="Enter Last Name"
                error={!!this.state.errors.lastName}
                helperText={this.state.errors.lastName ? 'Last Name is required' : ''}
                onChange={(e) => {
                  const value = e.target.value;
                  const onlyLetters = value.replace(/[^A-Za-z]/g, '');
                  this.handleLastName(onlyLetters.trim())}} 
                InputProps={{
                  style: { marginTop: "5px", borderRadius: "8px",  borderColor: "#CBD5E1"} }}
                />
            </Grid>

            <Grid item xs={12}>
              <InputLabel
                htmlFor="phoneNumber"
                style={webStyle.contactUsDrawerInputLabel}
              >
                Phone number
              </InputLabel>
              <PhoneInput specialLabel=""
                country={"ie"}
                data-test-id="txtPhoneInput"               
                value={this.state.phoneNumber}
                onChange={(value, country) => {
                    if (country && 'dialCode' in country) {
                      this.setState({
                        phoneNumber: value,
                        countryCode: country.dialCode
                      });
                    } }}
                inputProps={{
                  style: { width: "100%",
                    marginTop: "5px",
                    borderRadius: "8px",                  
                  } }}
              />
            </Grid>

            <Grid item xs={12}>
              <InputLabel  htmlFor="subject"            
                style={webStyle.contactUsDrawerInputLabel}
              >
                Subject
              </InputLabel>

              <TextField fullWidth
                select
                data-test-id="subject"
                placeholder="Select an option"
                variant="outlined"
                value={this.state.subject}
                onChange={this.handleSubjectChange}             
                InputProps={{
                  style: { borderRadius: "8px",               
                    marginTop: "5px",
                  } }}
                error={!!this.state.errors.subject}
                helperText={this.state.errors.subject ? 'Subject is required' : ''}
                SelectProps={{
                  displayEmpty: true,
                  MenuProps: {
                    PaperProps: {
                      style: {
                        transform: 'translateY(56%)', 
                        maxHeight: 200,
                        overflowY: 'auto',
                      },
                    },
                  },
                }}
              >
                <MenuItem disabled value="">
                  <span 
                    style={{ fontFamily: "'MyFont', 'Halyard Display'", color: "#94A3B8", fontSize: "16px", fontWeight: 700, letterSpacing: "0.05em" }}>
                    Select an option
                  </span>
                </MenuItem>
                {this.state.subjects.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>

              <TextField fullWidth
                multiline
                data-test-id="txtInputDescription"
                placeholder="Describe your Inquiry..."             
                rows={4}
                value={this.state.description}
                onChange={this.handleDescriptionChange}
                variant="outlined"
                InputProps={{ style: { borderRadius: "8px", marginTop: "10px", } }}
                error={!!this.state.errors.description}
                helperText={this.state.errors.description ? 'Description is required' : ''}
              />
            </Grid>
          </Grid>
        </Box>
        <Box mr={2} ml={2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center", }}
          >
            {this.formSubmittedSuccessfully()}
            {this.formSubmissionFailed()}
          <Button fullWidth
            data-test-id="submitButton"
            variant="contained"           
            style={{ ...webStyle.contactDrawerSubmitBtnMobile,             
              position: "relative",
              bottom:"20px",
              textTransform: "capitalize",             
              backgroundColor: this.state.formValid ? "#14362E" : "#E2E8F0", }}
            onClick={this.handleSubmitButton}
            >
            Submit
          </Button>
            </Box>
      </Drawer>
    );
  };

  contactUsWeb = () => {
    return(
      <Box className="contact">
        <Box style={{display:"flex", justifyContent:"space-between", cursor: "pointer"}}>
        <Box style={{display:"flex", justifyContent:"flex-start"}}>
          <img src={contactus} alt="contactus" />
          <Typography style={{marginLeft:"10px", fontFamily:"MyFont", letterSpacing: "0.05em"}}
            onClick={this.toggleContactUsDrawer(true)}
          >
            Contact us
          </Typography>
        </Box>
        <img src={image_chevron}
          alt="image_chevron"
          onClick={this.toggleContactUsDrawer(true)}
          style={{ position: "absolute", right: "1%" }}         
        />
        </Box>
      {this.contactUsDrawer()}
      </Box>
    );
  }

  contactUsMobile = () => {
    return(
      <Box style={{marginTop:"50px", position:"relative"}}>
        <Box style={{display:"flex", alignItems:"center", cursor: "pointer"}}>
        <img src={contactus} alt="contactus" style={{marginLeft:"0px"}}/>
          <Typography style={{marginLeft:"10px",fontFamily: "'MyFont', 'Halyard Display'",color: "#0F172A",  fontSize: "16px", fontWeight: 700,}}
            onClick={this.toggleContactUsDrawer(true)}
          >
            Contact us
          </Typography>
          <img
            src={image_chevron}
            alt="image_chevron"
            style={{ position: "absolute", right: "10%"}}
            onClick={this.toggleContactUsDrawer(true)}
          />
        </Box>
      {this.contactUsDrawerMobile()}
    </Box>
    );
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Container maxWidth={false}>
        <Hidden smDown>{this.contactUsWeb()}</Hidden>
        <Hidden mdUp>{this.contactUsMobile()}</Hidden>
      </Container>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default ContactUs;

const webStyle = {
  contactUsDrawerTxt: {
    fontFamily: "MyFont",
    letterSpacing: "1.4",
    color: "#1E1E1E",
    fontSize: "14px",
    marginBottom: "20px",
  },
  contactUsDrawerInputLabel: {
    fontSize: "16px",
    fontWeight: 700,
    color: "#334155",
  },
  contactDrawerSubmitBtn: {
    height: "45px",
    color: "white",
    fontWeight: 700,
    fontSize: "16px",
    borderRadius: "8px",
    fontFamily: "'MyFont', 'Halyard Display'",
    letterSpacing: "1px",
    marginTop: "20px",
    width: "80%",
  },
  contactDrawerSubmitBtnMobile: {
    height: "45px",
    color: "white",
    fontWeight: 700,
    fontSize: "16px",
    borderRadius: "8px",
    fontFamily: "'MyFont', 'Halyard Display'",
    letterSpacing: "1px",
    marginTop: "20px",
    width: "90%",
  },
  modalStyle: {
    position: "absolute" as "absolute",
    top: "50%",
    left: {xs: "50%",md: "50%"},   
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    width: {xs: "70%",md: "400px"},
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxShadow: 24,
    p: 4,
  },
  modalImg: {
    width: "80px",
    height: "80px"
  },
  modalStyleTypo: {
    fontSize: "24px", 
    fontWeight: "bold",
    color: "#14362E",
    marginTop: "5%",
    width: {xs: "90%", md: "70%"},
    fontFamily: "'MyFont', 'Halyard Display'",
    textAlign: "center", 
    letterSpacing: "0.05em"
  },
  modalStyleTypoSecond: {
    fontSize: "14px",  
    marginTop: "3%",
    color: "#0F172A",
    width: "100%",
    fontFamily: "'MyFont', 'Halyard Display'",
    textAlign: "center", 
    letterSpacing: "0.08em"
  }
};
// Customizable Area End
